var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import { FirebaseApp, Firebase } from '@manow/service';
import { user, authState, idToken } from 'rxfire/auth';

function getCurrentUser() {
  return new Promise(function (resolve, reject) {
    var unsubscribe = FirebaseApp.auth().onAuthStateChanged(function (user) {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

var sendEmailVerification = function sendEmailVerification(email, continueURL) {
  return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4, FirebaseApp.functions().httpsCallable('verifyEmail')({
            email: email,
            continueURL: continueURL
          })];

        case 1:
          _a.sent();

          return [2, Promise.resolve()];
      }
    });
  });
};

var sendResetPwdEmail = function sendResetPwdEmail(email) {
  return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4, FirebaseApp.functions().httpsCallable('resetPassword')({
            email: email
          })];

        case 1:
          _a.sent();

          return [2, Promise.resolve()];
      }
    });
  });
};

export var client = {
  login: function login(payload) {
    var email = payload.email,
        password = payload.password;
    return FirebaseApp.auth().signInWithEmailAndPassword(email, password);
  },
  logout: function logout() {
    return FirebaseApp.auth().signOut();
  },
  register: function register(payload) {
    var email = payload.email,
        password = payload.password,
        continueURL = payload.continueURL;
    return FirebaseApp.auth().createUserWithEmailAndPassword(email, password).then(function (_a) {
      var user = _a.user;

      if (user && user.emailVerified === false) {
        return sendEmailVerification(user.email, continueURL);
      }

      return Promise.resolve();
    }).catch(function (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorCode, errorMessage);
      return Promise.reject(error);
    });
  },
  isAuthenticated: function isAuthenticated() {
    return !!FirebaseApp.auth().currentUser;
  },
  getUser: function getUser() {
    var currentUser = FirebaseApp.auth().currentUser;

    if (!currentUser) {
      return getCurrentUser();
    }

    return currentUser;
  },
  getClient: function getClient() {
    return FirebaseApp.firestore();
  },
  updatePassword: function updatePassword(pwd) {
    var currentUser = FirebaseApp.auth().currentUser;
    return currentUser.updatePassword(pwd);
  },
  signInWithGoogle: function signInWithGoogle() {
    return FirebaseApp.auth().signInWithPopup(new Firebase.auth.GoogleAuthProvider());
  },
  signInWithFacebook: function signInWithFacebook() {
    var provider = new Firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    return FirebaseApp.auth().signInWithPopup(provider);
  },
  signInWithTwitter: function signInWithTwitter() {
    var provider = new Firebase.auth.TwitterAuthProvider();
    return FirebaseApp.auth().signInWithPopup(provider);
  },
  sendVerificationEmail: function sendVerificationEmail(continueURL) {
    return __awaiter(void 0, void 0, void 0, function () {
      var currentUser, res;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            currentUser = FirebaseApp.auth().currentUser;
            debugger;
            return [4, sendEmailVerification(currentUser.email, continueURL)];

          case 1:
            res = _a.sent();
            return [2, res];
        }
      });
    });
  },
  forgetPassword: function forgetPassword(email) {
    return __awaiter(void 0, void 0, void 0, function () {
      var res;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, sendResetPwdEmail(email)];

          case 1:
            res = _a.sent();
            return [2, res];
        }
      });
    });
  },
  passwordReset: function passwordReset(code, pwd) {
    return FirebaseApp.auth().confirmPasswordReset(code, pwd);
  }
};
export var GetAuthState = function GetAuthState() {
  return authState(FirebaseApp.auth());
};
export var GetUser = function GetUser() {
  return user(FirebaseApp.auth());
};
export var GetIdToken = function GetIdToken() {
  return idToken(FirebaseApp.auth());
};
export var SignOut = function SignOut() {
  return FirebaseApp.auth().signOut();
};