var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import React, { useState } from 'react';
import { useAsync } from 'react-use';
import { Card } from 'antd';
var AuthContext = React.createContext({
  user: undefined,
  login: function login() {
    return Promise.reject('Not impletemed');
  },
  logout: function logout() {
    return Promise.reject('Not impletemed');
  },
  register: function register() {
    return Promise.reject('Not impletemed');
  },
  updatePassword: function updatePassword() {
    return Promise.reject('Not Impletemed');
  },
  signInWithGoogle: function signInWithGoogle() {
    return Promise.reject('Not impletmemed');
  },
  signInWithFacebook: function signInWithFacebook() {
    return Promise.reject('Not impletemed');
  },
  signInWithTwitter: function signInWithTwitter() {
    return Promise.reject('Not impletemed');
  },
  sendVerificationEmail: function sendVerificationEmail() {
    return Promise.reject('Not impletemed');
  },
  forgetPassword: function forgetPassword(email) {
    return Promise.reject('Not impletemed');
  },
  passwordReset: function passwordReset(code, pwd) {
    return Promise.reject('Not impletemed');
  },
  client: undefined
});
export var AuthProvider = function AuthProvider(_a) {
  var children = _a.children,
      client = _a.client,
      _b = _a.loadingComponent,
      loadingComponent = _b === void 0 ? React.createElement(Card, {
    loading: true
  }) : _b;

  var _c = useState(undefined),
      user = _c[0],
      setUser = _c[1];

  var state = useAsync(function () {
    return __awaiter(void 0, void 0, void 0, function () {
      var currentUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            console.log('getting user');
            return [4, client.getUser()];

          case 1:
            currentUser = _a.sent();
            console.log('current user', currentUser);
            setUser(currentUser);
            return [2, function () {
              console.log('clean up');
            }];
        }
      });
    });
  }, []);

  var login = function login(payload) {
    return __awaiter(void 0, void 0, void 0, function () {
      var currentUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, client.login(payload)];

          case 1:
            _a.sent();

            return [4, client.getUser()];

          case 2:
            currentUser = _a.sent();
            setUser(currentUser);
            return [2, Promise.resolve(currentUser)];
        }
      });
    });
  };

  var logout = function logout() {
    return __awaiter(void 0, void 0, void 0, function () {
      var currentUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, client.logout()];

          case 1:
            _a.sent();

            return [4, client.getUser()];

          case 2:
            currentUser = _a.sent();
            setUser(currentUser);
            return [2];
        }
      });
    });
  };

  var register = function register(payload) {
    return __awaiter(void 0, void 0, void 0, function () {
      var currentUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, client.register(payload)];

          case 1:
            _a.sent();

            return [4, client.getUser()];

          case 2:
            currentUser = _a.sent();
            setUser(currentUser);
            return [2, Promise.resolve(currentUser)];
        }
      });
    });
  };

  var updatePassword = function updatePassword(pwd) {
    return client.updatePassword(pwd);
  };

  var signInWithGoogle = function signInWithGoogle() {
    return __awaiter(void 0, void 0, void 0, function () {
      var currentUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, client.signInWithGoogle()];

          case 1:
            _a.sent();

            return [4, client.getUser()];

          case 2:
            currentUser = _a.sent();
            setUser(currentUser);
            return [2, Promise.resolve(currentUser)];
        }
      });
    });
  };

  var signInWithFacebook = function signInWithFacebook() {
    return __awaiter(void 0, void 0, void 0, function () {
      var currentUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, client.signInWithFacebook()];

          case 1:
            _a.sent();

            return [4, client.getUser()];

          case 2:
            currentUser = _a.sent();
            setUser(currentUser);
            return [2, Promise.resolve(currentUser)];
        }
      });
    });
  };

  var signInWithTwitter = function signInWithTwitter() {
    return __awaiter(void 0, void 0, void 0, function () {
      var currentUser;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, client.signInWithTwitter()];

          case 1:
            _a.sent();

            return [4, client.getUser()];

          case 2:
            currentUser = _a.sent();
            setUser(currentUser);
            return [2, Promise.resolve(currentUser)];
        }
      });
    });
  };

  var sendVerificationEmail = function sendVerificationEmail(continueURL) {
    return __awaiter(void 0, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2, client.sendVerificationEmail(continueURL)];
      });
    });
  };

  var forgetPassword = function forgetPassword(email) {
    return __awaiter(void 0, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, client.forgetPassword(email)];

          case 1:
            return [2, _a.sent()];
        }
      });
    });
  };

  var passwordReset = function passwordReset(code, pwd) {
    return __awaiter(void 0, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, client.passwordReset(code, pwd)];

          case 1:
            return [2, _a.sent()];
        }
      });
    });
  };

  var ctx = {
    user: user,
    login: login,
    logout: logout,
    register: register,
    updatePassword: updatePassword,
    signInWithGoogle: signInWithGoogle,
    signInWithFacebook: signInWithFacebook,
    signInWithTwitter: signInWithTwitter,
    sendVerificationEmail: sendVerificationEmail,
    forgetPassword: forgetPassword,
    passwordReset: passwordReset,
    client: client.getClient()
  };
  return React.createElement(AuthContext.Provider, {
    value: ctx
  }, state.loading ? loadingComponent : children);
};
export function useAuth() {
  var context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}