import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { AuthProvider, client } from "@manow/auth";
import { Card } from "antd";
// import "./App.css";
// import "antd/dist/antd.css";
// import "antd-mobile/dist/antd-mobile.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import AuthLayout from "./components/layout/AuthLayout";
// import MoblieAuthLayout from "./components/layout/MoblieAuthLayout";

const MainLayout = lazy(() => import("./components/layout/MainLayout"));
const AnonymousLayout = lazy(() => import("./components/layout/AnonymousLayout"));
// const MoblieMainLayout = lazy(() =>
//   import("./components/layout/MoblieMainLayout")
// );

console.log("isMobile", isMobile);
console.log("isTablet", isTablet);

const App = () => (
  <AuthProvider client={client}>
    <Router>
      <Suspense fallback={<Card loading></Card>}>
        <Switch>
          <Route
            path={["/signin", "/signup", "/checkverifyemail", "/mobileauthportal", "/auth/action", "/forgetpassword", "/termandcondition", "/mobiletermandcondition"]}
            component={AuthLayout}
            exact={true}
          />
          <Route path="/home" component={AnonymousLayout}/>
          <Route path="/" component={MainLayout} />
        </Switch>
      </Suspense>
    </Router>
  </AuthProvider>
);

export default App;
