import React, { useState } from "react";
import { Typography, Button, Row, Col, Checkbox, Spin } from "antd";
import { useAuth } from "@manow/auth";
import { useHistory } from "react-router";
import { useEffectOnce } from "react-use";
import { User } from "@manow/service";

import logo from "../../asset/logo-white.png";

const TermAndCondition = ({ callback }) => {
  const { user } = useAuth();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState({});
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("th");
  useEffectOnce(() => {
    if (user) {
      User.GetUserInfoDetail(user.uid).subscribe((u) => {
        setUserInfo(u);
      });
    }
  });
  if (!user) {
    history.push("/");
    return null;
  }
  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };
  const handleAccept = () => {
    setLoading(true);
    User.UpdateUserInfo(user.uid, {
      ...userInfo,
      UserInfoAcceptTerm: true,
    }).subscribe(async (res) => {
      setLoading(false);
      console.log(res);
      callback();
    });
  };
  const handleSetLang = (newLang) => {
    setLang(newLang);
  };
  return (
    <React.Fragment>
      <Spin spinning={loading} tip="Loading...">
        <div>
          <Row
            style={{
              backgroundColor: "#54BFC5",
              width: "100%",
              minHeight: "236px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Col
              xs={4}
              sm={4}
              md={4}
              lg={3}
              xl={3}
              style={{ textAlign: "center" }}
            >
              <img src={logo} alt="" style={{ margin: "46px 0 0 33px" }}></img>
            </Col>
            <Col
              xs={20}
              sm={20}
              md={20}
              lg={21}
              xl={21}
              style={{ padding: "55px 45px 45px" }}
            >
              <Typography
                style={{
                  fontSize: "36px",
                  lineHeight: "54px",
                  fontWeight: "bold",
                  color: "#fff",
                  marginBottom: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    ขอขอบคุณที่ท่านสนใจเลือกใช้บริการของเรา
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    Thank you for your interest in our services
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#fff",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    เพื่อเป็นการปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                    พ.ศ. 2562 ตลอดจนเพื่อเป็นการรักษา ผลประโยชน์ของท่าน
                    ก่อนที่ท่านจะเข้าใช้บริการของเรา
                    โปรดอ่านและทำความเข้าใจข้อตกลงให้ความยินยอมใน การจัดเก็บ ใช้
                    เปิดเผย และหรือ ประมวลผลข้อมูลส่วนบุคคล ด้านล่างนี้
                    และกดปุ่ม “ตกลง” เพื่อให้ความยินยอม กับเรา ในการทำการรวมรวม
                    จัดเก็บ ใช้ เปิดเผย และ/หรือประมวลผลข้อมูลส่วนบุคคล
                    (Personal Data) บางส่วนของท่าน
                    เพื่อให้เราสามารถดำเนินการให้บริการต่อท่านได้
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    To comply with Personal Data Protection Act B.E. 2562
                    (2019), as well as to protect your own interest, before
                    using our services, please read the below terms and
                    conditions of Consent to Collect, Use, Disclose and/or
                    Process of the Personal Data and kindly click the “Agree”
                    button to give consent to us to collect, use, disclose
                    and/or process part of your personal data under such terms
                    and conditions, for us to be able to further provide
                    services to you.
                  </React.Fragment>
                )}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div
                style={{
                  display: "inline-flex",
                  width: "100px",
                  textAlign: "right",
                  marginTop: "19px",
                  float: "right",
                }}
              >
                <div
                  style={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    color: lang === "en" ? "#54BFC5" : "#000",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSetLang("en")}
                >
                  ENG
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    color: "#000",
                    margin: "0 5px",
                  }}
                >
                  |
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    color: lang === "th" ? "#54BFC5" : "#000",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSetLang("th")}
                >
                  THAI
                </div>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              backgroundColor: "#fff",
              width: "100%",
              paddingTop: "54px",
            }}
          >
            <Col
              xs={4}
              sm={4}
              md={4}
              lg={3}
              xl={3}
              style={{ textAlign: "center" }}
            ></Col>
            <Col
              xs={20}
              sm={20}
              md={20}
              lg={21}
              xl={21}
              style={{ padding: "0 45px" }}
            >
              <Typography
                style={{
                  fontSize: "36px",
                  lineHeight: "54px",
                  fontWeight: "bold",
                  color: "#000",
                  marginBottom: "54px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    ข้อตกลงให้ความยินยอมในการจัดเก็บ ใช้
                    เปิดเผยและประมวลผลข้อมูลส่วนบุคคล
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    Consent to Collect, Use, Disclose and/or Process of the
                    Personal Data
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    ข้าพเจ้าตกลงยินยอมให้บริษัท มานาวพอยท์ จำกัด (“มานาวพอยท์”,
                    “บริษัทฯ”) สามารถเก็บ ใช้ เปิดเผย และ/ หรือ ประมวลผล
                    ข้อมูลส่วนบุคคลบางส่วนของข้าพเจ้า (กล่าวคือ ชื่อ-สกุล
                    หรือชื่อทางธุรกิจ หมายเลขโทรศัพท์ อีเมลล์แอดเดรส
                    ข้อมูลเกี่ยวกับการใช้เว็บไซด์ ข้อมูลทางเทคนิค เช่น
                    ไอพีแอดเดรส ข้อมูลการล็อคอินของข้าพเจ้า
                    รายละเอียดของบราวเซอร์ เขตเวลาและเทคโนโลยีอื่นๆ
                    บนอุปกรณ์ของข้าพเจ้าที่ใช้ในการเข้า application ของ
                    มานาวพอยท์ ตลอดจนข้อมูลส่วนบุคคลใดๆ
                    ที่ข้าพเจ้าได้โพสหรือกรอกลงบน application ของมานาวพอยท์)
                    เพื่อให้บริการกับข้าพเจ้า และเพื่อตรวจสอบ วิเคราะห์
                    และพัฒนาการบริการให้กับข้าพเจ้าต่อไป
                    ข้าพเจ้าทราบและยอมรับว่า
                    ข้อมูลของข้าพเจ้าจะถูกเก็บรักษาไว้บนระบบของบริษัทฯ
                    ตราบเท่าที่บริษัทฯ มีความ
                    จำเป็นต้องใช้เพื่อให้บริการกับข้าพเจ้า
                    หรือตามระยะเวลาที่เหมาะสมเพื่อการปฏิบัติตามกฎหมาย บริษัทฯ
                    จะไม่ นำข้อมูลของข้าพเจ้าไปใช้เพื่อวัตถุประสงค
                    ์อื่นหรือเปิดเผยข้อมูลเหล่านั้นต่อบุคคลอื่นไม่ว่าในกรณีใด ๆ
                    เว้นแต่เป็น การเปิดเผยข้อมูลให้กับพนักงานของบริษัทฯ
                    หรือผู้ที่เกี่ยวข้อง เท่าที่จำเป็น
                    เพื่อวัตถุประสงค์ตามที่ระบุไว้ใน ข้อตกลงนี้
                    หรือเว้นแต่เป็นกรณีที่กฎหมายกำหนดให้ต้องทำการเปิดเผย (เช่น
                    ตามคำสั่งศาลหรือคำสั่งขององค์กรที่ มีอำนาจอื่นๆ ของรัฐบาล
                    เป็นต้น)
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    I agree to let Manow Point Ltd. (“Manow Point”, “Company”)
                    collect, use, disclose and/or process part of my personal
                    data (e.g. name-surname or business name, telephone number,
                    email, data about how I use the website, technical data such
                    as my IP address, my login data, details about my browser,
                    time zone settings and other technology on the device I use
                    to access to Manow Point’s application or any personal data
                    that I post or store on Manow Point’s application, to enable
                    Manow Point to provide services to me, as well as to verify,
                    analyze and further develop their services.
                    <div
                      style={{
                        marginTop: "49px",
                      }}
                    >
                      I also acknowledge and agree that my personal data will be
                      stored on Manow Point’s system for so long as they are
                      required to use such data for providing services to me or
                      for the appropriate period as to comply with applicable
                      law. And the Company will not use my personal data to use
                      for other purpose or disclose my personal data to any
                      other persons, except to disclose to Manow Point’s staffs
                      or other relevant persons for the purpose as specified in
                      this consent, or in case that it is required by the law
                      (e.g. per orders from the court or other authorized
                      officials).
                    </div>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "36px",
                  lineHeight: "54px",
                  fontWeight: "bold",
                  color: "#000",
                  marginTop: "54px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    นโยบายคุ้มครองข้อมูลส่วนบุคคลมานาว
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>Privacy Policy Manow</React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <div style={{ marginTop: "25px" }}>
                      บริษัท มานาวพอยท์ ("บริษัท")
                      เป็นผู้พัฒนาและเจ้าของมานาวซึ่งถือเป็นบริการ
                      หนึ่งของบริษัท ("บริการ")
                      เมื่อท่านสมัครบัญชีใช้งานของบริษัทผ่านมานาวท่านจะถือว่าเป็นผู้ใช้บริการของบริษัท
                      ("ผู้ใช้บริการ") และในการให้บริการแก่ท่านบริษัทมีความ
                      จำเป็นต้องประมวลผลข้อมูลส่วนบุคคลของผู้ใช้บริการดังนั้นบริษัทจึงมีจุดประสงค์ประกาศนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ขึ้นเพื่อแจ้งเกี่ยวกับสิทธิและหน้าที่รวมถึงเงื่อนไขต่างๆอันเกี่ยวเนื่องกับการเก็บรวบรวมใช้และเปิดเผยข้อมูล
                      ส่วนบุคคลที่บริษัทจะดำเนินการให้ผู้ใช้บริการในฐานะผู้ใช้บริการรับทราบ
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      นโยบายความเป็นส่วนตัวฉบับนี้มีผลใช้บังคับกับการให้บริการของบริษัทเท่านั้นไม่มีผลใช้บังคับกับแอปพลิเคชั่นและบริการหรือเว็บไซต์อื่นๆ
                      ที่อาจมีการเชื่อมต่อซึ่งเป็นของบุคคลภายนอกที่บริษัทไม่มีอำนาจควบคุม
                      และเป็นส่วนที่ผู้ใช้บริการต้องทำความตกลง
                      และศึกษาเกี่ยวกับนโยบายข้อมูลส่วนบุคคลสำหรับการใช้แอปพลิเคชั่นบริการหรือเว็บไซต์ดังกล่าวแยกต่างหาก
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      หากผู้ใช้บริการไม่ตกลงตามเงื่อนไขของนโยบายข้อมูลส่วนบุคคลฉบับนี้
                      หรือฉบับแก้ไขอื่นๆบริษัทสงวนสิทธิที่จะห้ามหรือไม่อนุญาตให้ผู้ใช้บริการดังกล่าวใช้บริการของบริษัทเนื่องจากการประมวลผลข้อมูลส่วนบุคคลภายใต้นโยบายฉบับนี้
                      มีความผลจำเป็นโดยตรงต่อการปฏิบัติตามหน้าที่ให้บริการโดยบริษัทแก่ผู้ใช้บริการทั้งนี้การที่ผู้ใช้บริการยังคงใช้บริการของบริษัทอยู่ผู้ใช้บริการดังกล่าวจะถือว่ายอมรับนโยบายฉบับนี้เสมอ
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      บริษัทอาจปรับปรุงนโยบายฉบับนี้ตามแต่ละระยะเวลาเพื่อให้สอดคล้องกับแนวปฏิบัติและกฎหมายข้อบังคับที่เกี่ยวข้องและให้สอดคล้องกับการให้บริการต่างๆของบริษัท
                      ทั้งนี้
                      บริษัทจะแจ้งให้ผู้ใช้บริการทราบถึงการเปลี่ยนแปลงด้วยการประกาศนโยบายฉบับปรับปรุงใหม่ให้ผู้ใช้บริการทราบ
                      โดยนโยบายนั้นจะถือว่ามีผลบังคับใช้เมื่อบริษัทได้ประกาศ
                    </div>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <div style={{ marginTop: "25px" }}>
                      Manow point company limited Company Limited (the
                      "Company") is the owner and the developer of Manow (the
                      "Service"). By signing up and registering to be the user
                      to use the service of Manow ("User"), the User
                      acknowledges and understands that the Company would need
                      to process the personally identifiable information (the
                      "PII") of such particular User. Thus, the Company
                      announces this Privacy Policy to notify the User of the
                      rights and obligations as well as the conditions for the
                      collection, use, process and disclosure of the User's PII
                      in performing the Service.
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      This Privacy Policy is only applicable to the use of the
                      Service and shall not apply to the PII process undertaken
                      by other application or website developed and operated by
                      any other third-party that the Company does not have any
                      control over (the "Third Party") despite being displayed
                      or connected to the Service. The User understand and agree
                      that the User shall study and agree on the privacy policy
                      announced by those Third Party that are separated from
                      this Privacy Policy.
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      If the User does not accept this Privacy Policy and any
                      amendment thereof, the Company reserves the rights to
                      refrain from providing the Service to such User because
                      the PII processing defined hereunder are critical for the
                      provisions of the Services by the Company to the User. By
                      continuing to use the Service, the relevant User shall be
                      deemed to always accept and agree to the Privacy Policy.
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      The Company may amend or revise this Privacy Policy at any
                      time to assure the compliance with the relevant laws and
                      regulations and to assure the updates in the Service at
                      any time; provided that the Company will communicate the
                      amendments or revisions made to the User and the amendment
                      or revisions shall become effective once being
                      communicated.
                    </div>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: "bold",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <u>
                      ลักษณะข้อมูลส่วนบุคคลที่บริษัทประมวลผลในการให้บริการของบริษัท
                    </u>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <u>
                      PII being Processed by the Company i n the performance of
                      the Service
                    </u>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <div>
                      ในการให้บริการบริษัทจะเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลจากการที่ผู้ใช้บริการดำเนินการผ่านระบบการให้บริการดังต่อไปนี้
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      1 ข้อมูลที่บ่งชี้ตัวตนโดยตรง อาทิ ชื่อ อายุ สัญชาติ
                      วันเกิด
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      2 ข้อมูลการติดต่อ อาทิ ที่อยู่ สถานที่ติดต่อ เบอร์โทร
                      อีเมล
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      3 ข้อมูลการชำระเงิน อาทิ รายละเอียดการชำระเงิน บัตรเครดิต
                      และบัญชีธนาคาร
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      4 ข้อมูลการใช้บริการ อาทิ ชื่อบัญชีผู้ใช้ รหัสผ่าน
                      ประวัติการทำธุรกรรมต่างๆ ที่ผู้ใช้บริการ ดำเนินการ รวมถึง
                      ความสนใจของต่างๆของผู้ใช้บริการ
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      5 ข้อมูลทางเทคนิคในการระบุตัวตน อาทิ
                      หมายเลขระบุตำแหน่งคอมพิวเตอร์ (IP Address) ข้อมูลการใช้งาน
                      การตั้งค่า และการเชื่อมต่อบราวเซอร์ ของอุปกรณ์
                      ที่ผู้ใช้บริการ ใช้ในการใช้บริการของบริษัท
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      6 รูปภาพ , การบันทึกเสียง , การบันทึกภาพเคลื่อนไหว
                    </div>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <div>
                      In order to perform the required Services, the Company
                      would need to collect and process the following PII from
                      the User:
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      1 Directly Identifiable PII such as name, age, nationality
                      and date of birth
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      2 Contact information such as address, phone number, and
                      e-mail address
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      3 Payment information such as the payment transaction
                      information, bank account information and credit card
                      information
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      4 Transactional data, such as username - password and
                      logs, transaction number and history that the User
                      transact and use the Service, including the gathered
                      preference and interest from the transactional behavior
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      5 Technical PII such as IP address number, the Service
                      usage information, and web browser status and setting that
                      have been used to connect to the Service.
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      6 image , sound record , Video record
                    </div>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: "bold",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <u>วัตถุประสงค์ในการประมวลผลข้อมูล</u>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <u>Objectives for PII Process</u>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <div>
                      1 บริษัทมีความจำเป็นเก็บ รวบรวม ใช้ข้อมูลส่วนบุคคล
                      ของผู้ใช้บริการ เพื่อการให้บริการที่กำหนด และ
                      ระบุไว้สำหรับแต่ละเงื่อนไขการให้บริการ ของบริษัท
                      อันรวมถึงเพื่อการยืนยันตัวตน
                      และติดตามในการทำธุรกรรมต่างๆของผู้ใช้บริการ
                      เพื่อการตรวจสอบเงื่อนไข การชำระเงินค่าบริการ
                      เพื่อใช้ในการติดต่อสื่อสาร กับผู้ใช้บริการ เป็นต้น
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      2 บริษัทมีความจำเป็นเก็บ และ
                      ใช้ข้อมูลส่วนบุคคลของผู้ใช้บริการ
                      เพื่อการวิเคราะห์ความสนใจ ของผู้ใช้บริการ
                      เพื่อให้สามารถเสนอสิทธิประโยชน์หรือบริการตาม
                      ความสนใจของผู้ใช้ บริการได้มากขึ้น หรือ
                      เพื่อการสร้างความสัมพันธ์ที่ดีขึ้นระหว่างบริษัทและผู้ใช้บริการ
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      3 บริษัทมีความจำเป็นจัดเก็บ และ ใช้ข้อมูลส่วนบุคคล
                      ของผู้ใช้บริการ
                      เพื่อวัตถุประสงค์ในการให้บริการสนับสนุนอื่นๆ เช่น
                      การติดต่อสอบถามข้อมูล ติชม แสดงความคิดเห็น หลังการบริการ
                      หรือ การส่งคำร้องต่างๆ
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      4 บริษัทมีความจำเป็นจัดเก็บข้อมูลส่วนบุคคลของผู้ใช้บริการ
                      เพื่อปฏิบัติตามข้อกฎหมาย และ ระเบียบบังคับใช้ของรัฐ เช่น
                      การจัดทำเอกสารภาษี หัก ณ ที่จ่าย หรือ การดำเนินการอื่นๆ
                      ที่กฎหมายกำหนด ทั้งนี้
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      บริษัทจะเก็บและรวบรวมข้อมูลส่วนบุคคลของผู้ใช้บริการไว้ตลอดระยะเวลา
                      ตราบเท่าที่ผู้ใช้ บริการยังคงเป็นผู้ใช้บริการของบริษัทอยู่
                      และบริษัทสงวนสิทธิเก็บข้อมูล ไว้อีกเป็นระยะเวลา 3 ปีหลัง
                      จากผู้ใช้บริการยกเลิกการใช้บริการ เพื่อประโยชน์ในการปกป้อง
                      และต่อสู้สิทธิต่างๆของบริษัท เว้นแต่ กฎหมายที่เกี่ยวข้อง
                      กำหนดให้บริษัท
                      มีหน้าที่เก็บข้อมูลส่วนบุคคลไว้เป็นระยะเวลาอื่น
                      บริษัทอาจมี
                      ความจำเป็นต้องเก็บข้อมูลไว้เป็นระยะเวลาที่ยาวนานกว่า 3 ปี
                    </div>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <div>
                      1 The Company would need to collect, restore and use the
                      PII of the User in order to perform any Services as
                      defined under the terms of service, including without
                      limitation to verify the identity and eligibility of the
                      User; to validate the payment conditions for any Service
                      fee (if any); to monitor the transaction executed by the
                      User; and to contact the relevant Use;
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      2 The Company would need to collect and analyze the PII of
                      the User in order to assess the User’s interest with an
                      aim to provide customized and personalized Service that
                      would meet the User’s interest and preference and to
                      improve the User’s experience;
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      3 The Company would need to collect and restore the User’s
                      PII in order to provide other support services after the
                      Service delivery such as in the satisfaction survey or the
                      support and complaint redress mechanism;
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      4 The Company may need to collect and restore the User’s
                      PII as obliged under the applicable laws and regulations,
                      for instance, for the withholding tax payment purpose.
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      The Company intends to store the PII of each relevant User
                      for throughout the period of time that the User is still
                      the registered User and for 3 (three) years after the
                      User’s termination for the legitimate interest of the
                      Company in the legal proceedings that the Company may have
                      against the relevant User; provided that in case that the
                      relevant laws requires the storage of the PII for the
                      longer period than defined, the Company may need to do so.
                    </div>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: "bold",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <u>การเปิดเผยข้อมูลส่วนบุคคล</u>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <u>Disclosure of the PII</u>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <div>
                      เพื่อการให้บริการ ตามเงื่อนไขที่ระบุไว้ บริษัทอาจมีความ
                      จำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของผู้ ใช้บริการ ในกรณี
                      ดังนี้
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      1
                      บริษัทอาจมีความจำเป็นเปิดเผยให้แก่ผู้ให้บริการภายนอกของบริษัท
                      ที่ได้รับการว่าจ้างจากบริษัทให้ช่วยเหลือ
                      สนับสนุนการให้บริการของบริษัท เช่น ที่ปรึกษา
                      ผู้รับจ้างให้บริการ ผู้รับจ้างขนส่ง
                      รวมถึงผู้ให้บริการภายนอกที่ให้บริการประเมินการบริการของบริษัท
                      เช่น Google Analytics ทั้งนี้ บริษัทจะเปิดเผยข้อมูล
                      เฉพาะเท่าที่จำเป็นเท่านั้น
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      2
                      บริษัทอาจเปิดเผยด้วยการเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ในระบบระบบคอมพิวเตอร์
                      ที่ใช้บริการ ได้แก่ Google Cloud Platform
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      3 บริษัทอาจเปิดเผยข้อมูล ด้วยความจำเป็น
                      เพื่อประโยชน์ในการปกป้องและต่อสู้สิทธิของบริษัท
                      หรือเพื่อการป้องกัน และตรวจสอบลักษณะการกระทำผิดที่
                      เกี่ยวข้องกับการใช้บริการของบริษัท โดยผู้ใช้บริการ
                      ในลักษณะต่างๆ โดยบริษัทจะดำเนินการดังกล่าวเท่าที่จำเป็น
                      เท่านั้น
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      4 ในกรณีที่บริษัทมีหน้าที่ตามกฎหมาย
                      หรืออยู่ภายใต้บังคับคำพิพากษา หรือคำคำสั่งของ
                      หน่วยงานราชการ
                      บริษัทอาจมีความจำเป็นต้องเปิดเผยข้อมูลให้แก่
                      หน่วยงานดังกล่าว เพื่อ
                      เป็นการปฏิบัติตามหน้าที่ที่มีตามกฎหมาย
                    </div>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <div>
                      In order to assure the performance of the Service as
                      committed, the Company may need to disclose the User’s PII
                      in the following circumstances:
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      1 To disclose the User’s PII to the outsourced service
                      providers engaged in the performing any direct support
                      service in the Service performance by the Company to the
                      User, including without limitation the advisors as well as
                      the third party service provider (i.e. logistic service
                      provider); provided that the Company shall only disclose
                      the User’s PII strictly on the need to know basis only;
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      2 To disclose the User’s PII in the usage of the storage
                      service on Computer System Google Cloud Platform;
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      3 To disclose the User’ PII to third party in the legal
                      proceedings to protect the Company’s legitimate rights or
                      to detect and prevent any fraud on the Platform; provided
                      that such disclosure shall be done on the limited and
                      specific purposes as defined only;
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      4 In case that the Company is obliged under the applicable
                      laws, court judgment or administrative order to disclose
                      any PII of any particular users, the Company would need to
                      do so.
                    </div>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: "bold",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <u>Cookies ที่บริษัทใช้ในการให้บริการ</u>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <u>
                      What are Cookies that the Company is using in Service
                      Performance?
                    </u>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <div>
                      Cookies คือ text files ที่อยู่ในคอมพิวเตอร์ของผู้ใช้บริการ
                      ใช้เพื่อจัดเก็บรายละเอียดข้อมูล log การ ใช้งานอินเตอร์เน็ต
                      หรือพฤติกรรมการใช้บริการของผู้ใช้บริการ ทั้งนี้
                      เพื่อรับประกันประสิทธิภาพใน
                      การให้บริการของบริษัทแก่ผู้ใช้บริการ
                      บริษัทมีความจำเป็นต้องใช้คุกกี้หลายประเภทเพื่อจุดประสงค์
                      ต่างกันไป ตังที่ระบุต่อไปนี้
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      1 Functionality Cookies:
                      ใช้ในการจดจำสิ่งที่ผู้ใช้บริการเลือก
                      หรือตั้งค่าบนแพลตฟอร์ม เช่น ชื่อบัญชีผู้ใช้ ภาษา ฟ้อนต์
                      และรูปแบบแพลตฟอร์ม เพื่อการนำเสนอข้อมูลที่ตรงความต้องการ
                      เฉพาะบุคคล ให้แก่ผู้ใช้บริการได้มากขึ้น
                      ตามการตั้งค่าต่างที่เลือกไว้
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      2 Advertising Cookies:
                      ใช้ในการจดจำสิ่งที่ท่านเคยเยี่ยมชมและรวมถึง ลักษณะการใช้
                      บริการของผู้ใช้บริการ เพื่อนำเสนอสินค้า หรือบริการ
                      ที่เกี่ยวข้อง และตรงกับความสนใจของ ผู้ใช้บริการ
                      และใช้เพื่อการประเมินประสิทธิผลของการใช้ฟังก์ชั่นต่างๆของระบบ
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      3 Strictly Necessary Cookies
                      เป็นคุกกี้ประเภทที่มีความจำเป็นอย่างมากต่อการทำงานเพื่อการให้บริการของบริษัท
                      มีความจำเป็น เพื่อให้การใช้บริการโดย ผู้ใช้งาน สามารถทำได้
                      อย่างทั่วถึงและปลอดภัย
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      แม้ว่าการใช้คุกกี้จะมีประโยชน์ในการเสริมประสิทธิภาพในการให้บริการ
                      และการทำงานให้บริการ ของบริษัท แต่หากผู้ใช้บริการต้องการ
                      ผู้ใช้บริการสามารถ ลบการตั้งค่าคุกกี้ บน Browser ของตน
                      เองได้ แต่อย่างไรก็ตาม ผู้ใช้บริการต้องรับทราบว่า
                      การดำเนินการดังกล่าวอาจส่งผลกระทบต่อประสิทธิภาพในการทำงานให้บริการของบริษัท
                      ได้ในบางส่วน ตามจุดประสงค์การทำงานของคุกกี้ที่ระบุไว้
                    </div>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <div>
                      Cookies are text files stored on the User’s computer
                      browser directory or program data subfolder in order to
                      keep data log of the User’s internet usage and the User’s
                      behavior or interaction when using the Service. For the
                      good performance of the Service, the Company need to use
                      various types of Cookies for various purposes as defined
                      below:
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      1 Functionality Cookies being used to record information
                      about choices the User have made when using the Service
                      such as personal settings, languages, and fonts so this
                      would allows the Company to tailor the Service that would
                      match the User’s preference setting;
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      2 Advertising Cookies being used to record the User’s
                      on-site behavior and history of the Service used and this
                      would allow the Company to provide the User the Services
                      that suit the User’s preferences and to assess the
                      performance of each function of the Service.
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      3 Strictly Necessary Cookies are essential for the User to
                      browse and use the Service and to use its features, such
                      as accessing secure areas of the Service system or
                      interface.
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      Even though the use of Cookies would enhance the
                      performance in providing Services to the User, the User
                      shall be entitled to disable the Cookies setting on the
                      User’s browser at their own will; provided that the User
                      shall acknowledge that the Cookies-disabled setting may
                      impact the efficiency and the performance of the Service
                      as defined in details for each type of Cookies above.
                    </div>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: "bold",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <u>
                      คำรับประกันการดำเนินมาตรการรักษาความมั่นคงปลอดภัยในข้อมูลที่เหมาะสม
                    </u>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <u>Representation on the Privacy Security</u>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <div>
                      บริษัทรับประกันจัดให้มีมาตรการการรักษาความมั่นคงปลอดภัยที่เหมาะสม
                      เพื่อป้องกัน การเข้าถึง การใช้ การเปลี่ยนแปลง การแก้ไข
                      หรือการเปิดเผยข้อมูลส่วนบุคคล โดยปราศจากอำนาจหรือโดย มิชอบ
                      นอกจากนี้
                      บริษัทได้กำหนดแนวปฏิบัติภายในเพื่อกำหนดสิทธิในการเข้าถึงหรือการใช้ข้อมูล
                      ส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล เพื่อรักษาความลับ
                      และความปลอดภัยของข้อมูล ทั้งนี้
                      บริษัทจะจัดให้มีการทบทวนมาตรการดังกล่าวเป็นระยะเพื่อความเหมาะสม
                      ตามกฎหมายที่เกี่ยวข้อง
                    </div>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <div>
                      The Company represents and guarantees that the Company
                      shall use the most appropriate security measures to
                      prevent the unauthorized access, amendment or disclosure
                      of the PII in any form or in any circumstance by either
                      internal or external persons and the Company commits to
                      review those measures on the regular basis with the strong
                      commitment to be in strict compliance with the applicable
                      laws.
                    </div>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: "bold",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <u>สิทธิของเจ้าของข้อมูล</u>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <u>Data Subject Rights</u>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <div>
                      บริษัทรับทราบ และเคารพสิทธิตามกฎหมายของผู้ใช้บริการ
                      ในส่วนที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของท่านผู้ใช้บริการซึ่งได้แก่สิทธิดังนี้
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      1 สิทธิขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคล
                      รวมถึงสิทธิในการขอแก้ไขข้อมูลดังกล่าวให้
                      เป็นปัจจุบันและถูกต้อง
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      2 สิทธิขอรับข้อมูลส่วนบุคคล
                      ในกรณีที่บริษัททำให้ข้อมูลนั้นอยู่ในรูปแบบที่สามารถอ่านหรือใช้
                      งานโดยทั่วไปด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติ
                      รวมถึง สิทธิขอให้ส่งหรือ โอน
                      ข้อมูลรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      3 สิทธิคัดค้านการประมวลผลข้อมูลส่วนบุคคล
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      4 สิทธิขอให้ลบหรือทำลาย
                      หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลได้
                      เมื่อข้อมูลนั้นหมดความจำเป็น
                      หรือเมื่อเจ้าของข้อมูลส่วนบุคคล ถอนความยินยอม
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      5 สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคลได้
                      ในกรณีเมื่อเป็นข้อมูลส่วนบุคคลที่ต้องลบ หรือ
                      เมื่อข้อมูลดังกล่าวหมดความจำเป็น สิทธิถอนความยินยอม
                      ในการประมวลผลข้อมูลที่ผู้ใช้บริการเคยให้ไว
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      ทั้งนี้ ผู้ใช้บริการสามารถติดต่อมายังบริษัท
                      เพื่อขอใช้สิทธิข้างต้นได้ ตามรายละเอียดการติดต่อที่
                      บริษัทได้กำหนดไว้ โดยไม่ต้องเสียค่าใช้จ่ายใดๆ
                      และบริษัทจะพิจารณา และ แจ้งผลการพิจารณาการ ขอใช้สิทธิ
                      ให้ผู้ใช้บริการทราบภายใน 30
                      วันนับแต่วันที่เราได้รับคำร้องขอดังกล่าว
                    </div>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <div>
                      The Company acknowledges and accepts the User’s rights as
                      the data subject over their PII as defined under the
                      applicable laws that include the following rights:
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      1 Right to access; to request for copy; and to rectify or
                      update their own PII;
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      2 Right to request for data portability of the PII that
                      the Company has processed in the readable forms by the
                      tools or automatic mechanics to other data controller;
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      3 Right to object to the PII process being undertaken;
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      4 Right to request for the erasure or de-identification of
                      any PII that does not have any necessary basis to process,
                      i.e. after the consent withdrawal;
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      5 Right to request to suspend the PII processing in case
                      that request for erasure is being exercised or it is
                      unneccessary to process such PII;
                    </div>
                    <div style={{ paddingTop: "18px" }}>
                      6 Right to withdraw consent that has been given.
                    </div>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: "bold",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <u>ช่องทางการติดต่อ</u>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <u>Contact Us</u>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000",
                  marginTop: "7px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <div>
                      รายละเอียดผู้ควบคุมข้อมูล
                      <br />
                      ชื่อ: บริษัท มานาวพอยท์ จำกัด
                      <br />
                      สถานที่ติดต่อ: 108/3 สังคมสงเคราะห์12 แขวงลาดพร้าว เขตลาดพร้าว กทม. 10230
                      <br />
                      ช่องทางการติดต่อ: 0990529052
                      <br />
                      อีเมล: info@manowpoint.com
                      <br />
                      เว็บไซต์: www.manowpoint.com
                    </div>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <div>
                      Data Processor
                      <br />
                      Name: manow point company limited
                      <br />
                      Address: 108/3 soi sungkom songkrao12 latphrao  khert lat phrao bangkok.
                      <br />
                      Contact detail: 0990529052
                      <br />
                      Email address: info@manowpoint.com
                      <br />
                      Website: www.manowpoint.com
                    </div>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: "bold",
                  color: "#000",
                  marginTop: "25px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <u>
                      รายละเอียดเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data
                      Protection Officer: DPO)
                    </u>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <u>Data Protection Officer</u>
                  </React.Fragment>
                )}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#000",
                  marginTop: "7px",
                }}
              >
                {lang === "th" && (
                  <React.Fragment>
                    <div>
                      ชื่อ: ทีมงานมานาว
                      <br />
                      สถานที่ติดต่อ: 108/3 สังคมสงเคราะห์12 แขวงลาดพร้าว เขตลาดพร้าว กทม. 10230
                      <br />
                      ช่องทางการติดต่อ: 0990529052
                      <br />
                      อีเมล: info@manowpoint.com
                      <br />
                      เว็บไซต์: www.manowpoint.com
                    </div>
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    <div>
                      Name: MANOW TEAM
                      <br />
                      Address: 108/3 soi sungkom songkrao12 latphrao  khert lat phrao bangkok.
                      <br />
                      Contact detail: 0990529052
                      <br />
                      Email address: info@manowpoint.com
                      <br />
                      Website: www.manowpoint.com
                    </div>
                  </React.Fragment>
                )}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={4} md={4} lg={3} xl={3}></Col>
            <Col
              xs={20}
              sm={20}
              md={20}
              lg={21}
              xl={21}
              style={{ padding: "50px 45px 0" }}
            >
              <Checkbox className="chk-term" onChange={(e) => handleCheck(e)}>
                {lang === "th" && (
                  <React.Fragment>
                    ข้าพเจ้าได้อ่านและเข้าใจข้อความข้างต้นโดยตลอดแล้ว
                    และตกลงให้ความยินยอมตามข้อความข้างต้น
                  </React.Fragment>
                )}
                {lang === "en" && (
                  <React.Fragment>
                    I hereby confirm that I have read and understood the above
                    terms and conditions entirely and agree to give consents per
                    written above.
                  </React.Fragment>
                )}
              </Checkbox>
            </Col>
          </Row>
          <Row>
            <Col xs={4} sm={4} md={4} lg={3} xl={3}></Col>
            <Col
              xs={20}
              sm={20}
              md={20}
              lg={21}
              xl={21}
              style={{ padding: "24px 45px 150px" }}
              className="btn-accept-term"
            >
              <Button
                size="large"
                style={{
                  background: "#54BFC5",
                  color: "white",
                  textAlign: "center",
                  fontSize: "14px",
                  width: "86px",
                  height: "36px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                }}
                disabled={!checked}
                onClick={() => handleAccept()}
              >
                {lang === "th" && <React.Fragment>ยอมรับ</React.Fragment>}
                {lang === "en" && <React.Fragment>Accept</React.Fragment>}
              </Button>
              <Button
                size="large"
                style={{
                  background: "#54BFC5",
                  color: "white",
                  textAlign: "center",
                  fontSize: "14px",
                  width: "86px",
                  height: "36px",
                  fontWeight: "bold",
                  marginLeft: "63px",
                  borderRadius: "5px",
                }}
              >
                {lang === "th" && <React.Fragment>ปฏิเสธ</React.Fragment>}
                {lang === "en" && <React.Fragment>Decline</React.Fragment>}
              </Button>
            </Col>
          </Row>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0",
            width: "100%",
            height: "118px",
            backgroundColor: "#54BFC5",
            color: "#fff",
            zIndex: "9999",
            textAlign: "center",
            verticalAlign: "middle",
            padding: "0 130px",
          }}
        >
          <div style={{ width: "100%", height: "100%", display: "flex" }}>
            <div style={{ margin: "auto 0" }}>
              {lang === "th" && (
                <React.Fragment>
                  <b>หมายเหตุ:</b>{" "}
                  ในส่วนที่เกี่ยวกับข้อมูลส่วนบุคคลที่ท่านได้ให้ไว้กับบริษัท
                  หากท่านมีความประสงค์ที่จะเข้าถึง แก้ไข เปลี่ยนแปลง ลบข้อมูลใดๆ
                  หรือมีคำถาม หรือต้องการข้อมูลใดๆ เพิ่มเติม
                  สามารถติดต่อเราได้ทางอีเมลล์: info@manowpoint.com
                </React.Fragment>
              )}
              {lang === "en" && (
                <React.Fragment>
                  <b>Remark:</b> With regard to the personal data you provided
                  to the Company, if you would like to access, correct, amend,
                  delete any of such personal data, have questions, or simply
                  want more information about this, please contact us via our
                  email : info@manowpoint.com
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </React.Fragment>
  );
};

export default TermAndCondition;
