import React from "react";
import { Icon } from "antd";

const Svg = () => (
  <svg
    width="16"
    height="25"
    viewBox="0 0 16 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 2L3 12.2245L14 23.5"
      stroke="#222222"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

const ArrowBackIcon = (props) => <Icon component={Svg} {...props} />;

export default ArrowBackIcon;
