"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var firebase_1 = require("../firebase");

var EventBeaconMarkerRefPath = function EventBeaconMarkerRefPath(EventKey) {
  return firebase_1.FirebaseApp.firestore().collection('Event').doc(EventKey).collection('EventBeaconMarker');
};

exports.CreateEventBeaconMarker = function (EventKey, Data) {
  return rxjs_1.from(EventBeaconMarkerRefPath(EventKey).add(Data));
};

exports.UpdateEventBeaconMarker = function (EventKey, EventBeaconMarkerKey, Data) {
  return rxjs_1.from(EventBeaconMarkerRefPath(EventKey).doc(EventBeaconMarkerKey).set(__assign(__assign({}, Data), {
    EventBeaconMarkerUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteEventBeaconMarker = function (EventKey, EventBeaconMarkerKey) {
  return rxjs_1.from(EventBeaconMarkerRefPath(EventKey).doc(EventBeaconMarkerKey).delete());
};

exports.GetAllEventBeaconMarkerList = function (EventKey) {
  return firestore_1.collectionData(EventBeaconMarkerRefPath(EventKey).orderBy('EventBeaconMarkerCreateTimestamp', 'desc'), 'EventBeaconMarkerKey');
};

exports.GetEventBeaconMarkerDetail = function (EventKey, EventBeaconMarkerKey) {
  return firestore_1.docData(EventBeaconMarkerRefPath(EventKey).doc(EventBeaconMarkerKey), 'EventBeaconMarkerKey');
};

exports.QueryEventBeaconMarkerList = function (EventKey, QueryField, QueryOperator, QueryValue) {
  return firestore_1.collectionData(EventBeaconMarkerRefPath(EventKey).where(QueryField, QueryOperator, QueryValue), 'EventBeaconMarkerKey');
};

exports.DeleteAllEventBeaconMarker = function (EventKey) {
  return exports.GetAllEventBeaconMarkerList(EventKey).pipe(operators_1.map(function (EventBeaconMarkerList) {
    var DeleteAllEventBeaconMarkerListBatch = firebase_1.FirebaseApp.firestore().batch();
    EventBeaconMarkerList.map(function (EventBeaconMarkerDoc) {
      return DeleteAllEventBeaconMarkerListBatch.delete(EventBeaconMarkerRefPath(EventKey).doc(EventBeaconMarkerDoc.EventBeaconMarkerKey));
    });
    return DeleteAllEventBeaconMarkerListBatch.commit();
  }));
};