import React, { useContext } from 'react';
import { Form as AntdForm } from 'antd';

const FormContext = React.createContext();
const MyForm = ({
  form,
  initialValues,
  children,
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 }
    }
  },
  onSubmit = console.log,
  onError = console.log
}) => {
  // useDeepCompareEffect(() => {
  //   form.setFieldsInitialValue(initialValues);
  //   // form.resetFields();
  //   return () => {};
  // }, [initialValues]);
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return onError(err);
      }
      return onSubmit(values);
    });
  };
  return (
    <FormContext.Provider value={{ ...form, initialValues }}>
      <AntdForm {...formItemLayout} onSubmit={handleSubmit}>
        {children}
      </AntdForm>
    </FormContext.Provider>
  );
};
/**
 * @returns {import('antd/lib/form/Form').WrappedFormUtils}
 */
const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext should use inside Form component');
  }
  return context;
};
const FormDataConsumer = ({ children }) => {
  /**
   * @type {import('antd/lib/form/Form').WrappedFormUtils}
   */
  const context = useFormContext();
  const { getFieldsValue } = context;
  const values = getFieldsValue();
  return children(values);
};
const Form = AntdForm.create({ name: 'event' })(MyForm);
export default Form;
export { useFormContext, FormDataConsumer, FormContext };
