import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import DesktopSignup from "./DesktopSignup";
import MobileSignup from "./MobileSignup";
import { Card } from "antd";

const Signup = () => {
  return (
    <Card
      style={{
        width: !isMobile && !isTablet ? "350px" : "100%",
        maxWidth: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      bordered={false}
      bodyStyle={{ padding: "0" }}
    >
      {!isMobile && !isTablet ? <DesktopSignup /> : <MobileSignup />}
    </Card>
  );
};

export default Signup;
