"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var firebaseConfig = {
  apiKey: "AIzaSyD12-zkrRAK0skQatIABaiYz0VjTw6Iyec",
  authDomain: "manowpoint-develop.firebaseapp.com",
  databaseURL: "https://manowpoint-develop.firebaseio.com",
  projectId: "manowpoint-develop",
  storageBucket: "manowpoint-develop.appspot.com",
  messagingSenderId: "68764258968",
  appId: "1:68764258968:web:db7b0863c4611f84b636ed"
};
exports.default = firebaseConfig;