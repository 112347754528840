import React, { useState } from "react";
import { useAuth } from "@manow/auth";
import { useHistory } from "react-router-dom";
import { Typography, Card, message, Spin, Button } from "antd";
import Form from "../../components/form/Form";
import TextInput from "../../components/form/TextInput";
import SubmitButtonInput from "../../components/form/SubmitButtonInput";

const ForgetPassword = () => {
  const history = useHistory();
  const { forgetPassword } = useAuth();
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [email, setEmail] = useState("");
  const handleSumbit = async (e) => {
    // console.log("handleSumbit e", e);
    setLoading(true);
    let isValid = true;
    setEmail(e.email);
    await forgetPassword(e.email).catch((error) => {
      message.error(error.message, 4);
      isValid = false;
    });
    setLoading(false);
    setCompleted(isValid);
  };
  const goTo = () => {
    history.push("/signin");
  };
  return (
    <Card
      style={{
        width: 350,
        maxWidth: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      bordered={false}
      bodyStyle={{ padding: "0" }}
    >
      <Spin spinning={loading}>
        <Card
          style={{
            marginTop: "48px",
          }}
          bordered={false}
          loading={loading}
        >
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#B9B9B9",
              marginBottom: "24px",
            }}
          >
            Forgot password
          </Typography>
          {!completed && (
            <Form onSubmit={(e) => handleSumbit(e)}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#000",
                  marginBottom: "24px",
                  lineHeight: "16px",
                }}
              >
                Please enter the email address you used to sign up. We will send
                you an email to reset your password.
              </Typography>
              <TextInput
                label=""
                formItemOptions={{
                  wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                  },
                }}
                name="email"
                placeholder="Email"
                type="email"
                size="large"
                style={{ borderColor: "#54BFC5", height: "45px" }}
                formRules={[
                  {
                    required: true,
                    message: "Please input your Email",
                  },
                ]}
              />
              <SubmitButtonInput
                block
                formItemOptions={{
                  wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                  },
                }}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  background: "#54BFC5",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  height: "40px",
                }}
                size="large"
              >
                Submit
              </SubmitButtonInput>
            </Form>
          )}
          {completed && (
            <React.Fragment>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#000",
                  marginBottom: "24px",
                  lineHeight: "16px",
                }}
              >
                A password reset message was sent to your email {email}. Please
                click the link in that message to reset your password.
                <br />
                <br />
                if you do not receive the password reset message within a few
                moments, please check your spam folder or other filtering tool.
              </Typography>
              <Button
                block
                formItemOptions={{
                  wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                  },
                }}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  background: "#54BFC5",
                  color: "white",
                  width: "100%",
                  textAlign: "center",
                  height: "40px",
                }}
                size="large"
                onClick={() => goTo()}
              >
                Back to Login
              </Button>
            </React.Fragment>
          )}
        </Card>
      </Spin>
    </Card>
  );
};

export default ForgetPassword;
