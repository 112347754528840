"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var firebase_1 = require("../firebase");

var firebase_2 = require("firebase");

var OrderTransactionRefPath = function OrderTransactionRefPath(userKey) {
  return firebase_1.FirebaseApp.firestore().collection('OrderTransaction');
};

var OrderTransactionPath = function OrderTransactionPath() {
  return firebase_1.FirebaseApp.firestore().collection('OrderTransaction');
};

var UserInfoRefPath = function UserInfoRefPath() {
  return firebase_1.FirebaseApp.firestore().collection('UserInfo');
};

var EventTicketRefPath = function EventTicketRefPath() {
  return firebase_1.FirebaseApp.firestore().collectionGroup('EventTicket');
};

var EventAttendeeRefPath = function EventAttendeeRefPath() {
  return firebase_1.FirebaseApp.firestore().collectionGroup('EventAttendee');
};

exports.CreateOrderTransaction = function (Userkey, Data) {
  return rxjs_1.from(OrderTransactionRefPath(Userkey).add(__assign(__assign({}, Data), {
    OrderTransactionCreateTimestamp: firebase_2.firestore.FieldValue.serverTimestamp()
  })));
};

exports.GetAllOrderTransactionList = function (Userkey) {
  return firestore_1.collectionData(OrderTransactionRefPath(Userkey).orderBy('OrderTransactionCreateTimestamp', 'desc'), 'OrderTransactionKey');
};

exports.GetAllOrderTransactions = function () {
  return firestore_1.collectionData(OrderTransactionPath(), 'OrderTransactionKey');
};

exports.GetOrderTransactionDetail = function (Userkey, OrderTransactionKey) {
  return firestore_1.docData(OrderTransactionRefPath(Userkey).doc(OrderTransactionKey), 'OrderTransactionKey');
};

exports.QueryOrderTransactionList = function (Userkey, QueryField, QueryOperator, QueryValue) {
  return firestore_1.collectionData(OrderTransactionRefPath(Userkey).where(QueryField, QueryOperator, QueryValue), 'OrderTransactionKey');
};

exports.GetOrderTransactionList = function (limit, query) {
  if (limit === void 0) {
    limit = 0;
  }

  if (query === void 0) {
    query = function query(ref) {
      return ref;
    };
  }

  var myRef = OrderTransactionPath().limit(limit);
  myRef = query(myRef);
  return firestore_1.collectionData(myRef, 'OrderTransactionKey');
};

var getUserInfo = function getUserInfo(order, isEdit) {
  if (isEdit && order.UserEditKey === undefined) return null;
  var key = isEdit ? order.UserEditKey : order.UserKey;
  return UserInfoRefPath().doc(key).get().then(function (doc) {
    var userValue = doc.data();
    return userValue;
  }).catch(function (e) {
    console.log(e);
  });
};

exports.GetOrderTransactionWithUserInfoList = function (limit, query) {
  if (limit === void 0) {
    limit = 0;
  }

  if (query === void 0) {
    query = function query(ref) {
      return ref;
    };
  }

  var myRef = OrderTransactionPath().limit(limit);
  myRef = query(myRef);
  return firestore_1.collectionData(myRef, 'OrderTransactionKey').pipe(operators_1.flatMap(function (orders) {
    return __awaiter(void 0, void 0, void 0, function () {
      var myOrdersWithUserInfo;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, Promise.all(orders.map(function (order) {
              return __awaiter(void 0, void 0, void 0, function () {
                var userInfo, userEdit, orderDetail, eventAttendeesSnap, eventAttendees, eventAttendeeList;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4, getUserInfo(order, false)];

                    case 1:
                      userInfo = _a.sent();
                      return [4, getUserInfo(order, true)];

                    case 2:
                      userEdit = _a.sent();
                      return [4, Promise.all(order.OrderTransactionDetail.map(function (detail) {
                        return __awaiter(void 0, void 0, void 0, function () {
                          var ticketSnap, tickets, ticketInfo;
                          return __generator(this, function (_a) {
                            switch (_a.label) {
                              case 0:
                                return [4, EventTicketRefPath().where('EventTicketKey', '==', detail.OrderTransactionTicketKey).get()];

                              case 1:
                                ticketSnap = _a.sent();
                                tickets = ticketSnap.docs;
                                ticketInfo = tickets.length > 0 ? tickets[0].data() : {};
                                return [2, __assign(__assign({}, detail), {
                                  ticketInfo: ticketInfo
                                })];
                            }
                          });
                        });
                      }))];

                    case 3:
                      orderDetail = _a.sent();
                      return [4, EventAttendeeRefPath().where('OrderTransactionKey', '==', order.OrderTransactionKey).get()];

                    case 4:
                      eventAttendeesSnap = _a.sent();
                      eventAttendees = eventAttendeesSnap.docs;
                      eventAttendeeList = eventAttendees.map(function (e) {
                        return __assign({}, e.data());
                      });
                      return [2, __assign(__assign({}, order), {
                        OrderTransactionDetail: orderDetail,
                        userInfo: userInfo,
                        userEdit: userEdit,
                        eventAttendeeList: eventAttendeeList
                      })];
                  }
                });
              });
            }))];

          case 1:
            myOrdersWithUserInfo = _a.sent();
            return [2, myOrdersWithUserInfo];
        }
      });
    });
  }));
};

exports.QueryOrderTransactionWithUserDetail = function (Userkey, QueryField, QueryOperator, QueryValue) {
  return firestore_1.collectionData(OrderTransactionRefPath(Userkey).where(QueryField, QueryOperator, QueryValue), 'OrderTransactionKey').pipe(operators_1.flatMap(function (orders) {
    return __awaiter(void 0, void 0, void 0, function () {
      var myOrdersWithUserInfo;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, Promise.all(orders.map(function (order) {
              return __awaiter(void 0, void 0, void 0, function () {
                var userInfo;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4, getUserInfo(order, false)];

                    case 1:
                      userInfo = _a.sent();
                      return [2, __assign(__assign({}, order), {
                        userInfo: userInfo
                      })];
                  }
                });
              });
            }))];

          case 1:
            myOrdersWithUserInfo = _a.sent();
            return [2, myOrdersWithUserInfo];
        }
      });
    });
  }));
};

exports.UpdateOrderTransaction = function (Userkey, OrderTransactionKey, Data) {
  return rxjs_1.from(OrderTransactionRefPath(Userkey).doc(OrderTransactionKey).set(__assign(__assign({}, Data), {
    OrderTransactionUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};