"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var firebase_1 = require("../firebase");

var EventTicketRefPath = function EventTicketRefPath(EventKey) {
  return firebase_1.FirebaseApp.firestore().collection('Event').doc(EventKey).collection('EventTicket');
};

exports.CreateEventTicket = function (EventKey, Data) {
  return rxjs_1.from(EventTicketRefPath(EventKey).add(Data));
};

exports.UpdateEventTicket = function (EventKey, EventTicketKey, Data) {
  return rxjs_1.from(EventTicketRefPath(EventKey).doc(EventTicketKey).set(__assign(__assign({}, Data), {
    UserInfoUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteEventTicket = function (EventKey, EventTicketKey) {
  return rxjs_1.from(EventTicketRefPath(EventKey).doc(EventTicketKey).delete());
};

exports.GetAllEventTicketList = function (EventKey) {
  return firestore_1.collectionData(EventTicketRefPath(EventKey).orderBy('EventTicketCreateTimestamp', 'desc'), 'EventTicketKey');
};

exports.GetEventTicketDetail = function (EventKey, EventTicketKey) {
  return firestore_1.docData(EventTicketRefPath(EventKey).doc(EventTicketKey), 'EventTicketKey');
};

exports.QueryEventTicketList = function (EventKey, QueryField, QueryOperator, QueryValue) {
  return firestore_1.collectionData(EventTicketRefPath(EventKey).where(QueryField, QueryOperator, QueryValue), 'EventTicketKey');
};

exports.GetAllEventTicketNotExpireList = function (EventKey) {
  return firestore_1.collectionData(EventTicketRefPath(EventKey).where('EventTicketExpiryDate', '>=', new Date()), 'EventTicketKey');
};

exports.DeleteAllEventTicket = function (EventKey) {
  return exports.GetAllEventTicketList(EventKey).pipe(operators_1.switchMap(function (EventTicketList) {
    var DeleteAllEventTicketListBatch = firebase_1.FirebaseApp.firestore().batch();
    EventTicketList.map(function (EventTicketDoc) {
      return DeleteAllEventTicketListBatch.delete(EventTicketRefPath(EventKey).doc(EventTicketDoc.EventTicketKey));
    });
    return DeleteAllEventTicketListBatch.commit();
  }), operators_1.take(1));
};

exports.UpdateEventTicketList = function (EventKey, EventTicketList) {
  return EventTicketList.map(function (EventTicketData) {
    if (EventTicketData.EventTicketKey) {
      return exports.UpdateEventTicket(EventKey, EventTicketData.EventTicketKey, EventTicketData).pipe(operators_1.take(1)).subscribe(function (_) {});
    } else {
      return exports.CreateEventTicket(EventKey, __assign(__assign({}, EventTicketData), {
        EventTicketCreateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp(),
        EventKey: EventKey
      })).pipe(operators_1.take(1)).subscribe(function (_) {});
    }
  });
};