import React from 'react';
import { Input } from 'antd';
import FormItemWrapper from './FormItemWrapper.js';

const { TextArea } = Input;

const TextInput = ({
  name,
  label,
  placeholder,
  rows,
  options,
  formItemOptions,
  type = "text",
  formRules = [],
  size,
  defaultValue = "",
  style={},
  suffix,
  addonAfter,
  onChangeFunc=null,
  ...rest
}) => {
  return (
    <FormItemWrapper name={name} label={label} options={options} formItemOptions={formItemOptions} formRules={formRules} defaultValue={defaultValue}>
      { rows ? <TextArea rows={rows} placeholder={placeholder} /> : <Input type={type} placeholder={placeholder} size={size} style={style} suffix={suffix} addonAfter={addonAfter} {...rest} onChange={(e) => {e.preventDefault(); if(onChangeFunc != null) {onChangeFunc(e.currentTarget.value); console.log("textinput e", e.currentTarget.value)}}} />}
    </FormItemWrapper>
  );
};

export default TextInput;
