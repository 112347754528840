import React, { useState } from "react";
import { useAuth } from "@manow/auth";
import { useHistory } from "react-router";
import { useEffectOnce } from "react-use";
import { Layout } from "antd";
import { Switch, Route } from "react-router";
import { isMobile, isTablet } from "react-device-detect";
import { User } from "@manow/service";
import Signin from "../../pages/auths/signin";
import Signup from "../../pages/auths/signup";
import ForgetPassword from "../../pages/auths/ForgetPassword";
import CheckVerifyEmail from "../../pages/auths/CheckVerifyEmail";
import MobileAuthPortal from "../../pages/auths/MobileAuthPortal";
import TermAndCondition from "../../pages/auths/DesktopTermAndCondition";
import MobileTermAndCondition from "../../pages/auths/MobileTermAndCondition";
// import VerifyEmail from "../../pages/auths/VerifyEmail";
import AuthAction from "../../pages/auths/Action";

const AuthLayout = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [userInfo, setUserInfo] = useState({});
  useEffectOnce(() => {
    if (user) {
      User.GetUserInfoDetail(user.uid).subscribe((u) => {
        setUserInfo(u);
      });
    }
  });
  console.log("userInfo", userInfo);
  if (
    user &&
    user.emailVerified &&
    (userInfo.UserInfoAcceptTerm !== undefined && userInfo.UserInfoAcceptTerm)
  ) {
    history.replace("/");
  }
  return (
    <React.Fragment>
      {!isMobile && !isTablet ? (
        <Layout style={{ backgroundColor: "#fff", height: "100%" }}>
          <Layout.Header
            style={{ textAlign: "center", backgroundColor: "#fff", height: 0 }}
          />
          <Layout.Content
            style={{
              padding: "0",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Switch>
              <Route path="/signin" component={Signin} exact />
              <Route path="/signup" component={Signup} exact />
              <Route path="/forgetpassword" component={ForgetPassword} exact />
              <Route
                path="/checkverifyemail"
                component={CheckVerifyEmail}
                exact
              />
              <Route
                path="/termandcondition"
                component={TermAndCondition}
                exact
              />
              <Route path="/auth/action" component={AuthAction} exact />
            </Switch>
          </Layout.Content>
          <Layout.Footer
            style={{
              textAlign: "center",
              backgroundColor: "#fff",
              height: 0,
              padding: 0,
            }}
          />
        </Layout>
      ) : (
        <div
          style={{
            position: "fixed",
            height: "100%",
            width: "100%",
            top: "0",
            backgroundColor: "#fff",
            overflow: "auto",
          }}
        >
          <Switch>
            <Route path="/signin" component={Signin} exact />
            <Route path="/signup" component={Signup} exact />
            <Route path="/forgetpassword" component={ForgetPassword} exact />
            <Route
              path="/mobileauthportal"
              component={MobileAuthPortal}
              exact
            />
            <Route
              path="/checkverifyemail"
              component={CheckVerifyEmail}
              exact
            />
            <Route
              path="/mobiletermandcondition"
              component={MobileTermAndCondition}
              exact
            />
            <Route path="/auth/action" component={AuthAction} exact />
          </Switch>
        </div>
      )}
    </React.Fragment>
  );
};

export default AuthLayout;
