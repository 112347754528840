var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import React from 'react';
import { Route } from 'react-router';
import { useAuth } from '../provider';

var UnauthenticatedRoute = function UnauthenticatedRoute(_a) {
  var _b = _a.authorizedComponent,
      authorizedComponent = _b === void 0 ? React.createElement("div", null, "Authorized") : _b,
      rest = __rest(_a, ["authorizedComponent"]);

  var user = useAuth().user;

  if (user) {
    return authorizedComponent;
  }

  return React.createElement(Route, __assign({}, rest));
};

export default UnauthenticatedRoute;