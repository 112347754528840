import React, { useState } from "react";
import { NavBar, Icon, WingBlank, ActivityIndicator } from "antd-mobile";
import { Typography, message } from "antd";
import { useAuth } from "@manow/auth";
import Form from "../../components/form/Form";
import TextInput from "../../components/form/TextInput";
import SubmitButtonInput from "../../components/form/SubmitButtonInput";
import { useHistory, Link } from "react-router-dom";
import logo from "../../asset/logo.png";
import fbLogo from "../../asset/fb-logo.png";
import gLogo from "../../asset/g-logo.png";
import twitLogo from "../../asset/twit-logo.png";
import ArrowBackIcon from "../../components/icon/ArrowBackIcon"

const MobileSignin = () => {
  const {
    login,
    signInWithFacebook,
    signInWithGoogle,
    signInWithTwitter,
  } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const { innerWidth: width, innerHeight: height } = global.window;
  const handleSubmit = async (formData) => {
    setLoading(true);
    await login(formData).catch((error) => {
      setLoading(false);
      message.error(error.message, 4);
    });
    history.push("/");
  };
  const handleLoginGoogle = async () => {
    setLoading(true);
    await signInWithGoogle().catch((error) => {
      setLoading(false);
      message.error(error.message, 4);
    });
    history.push("/");
  };
  const handleLoginFacebook = async () => {
    setLoading(true);
    await signInWithFacebook().catch((error) => {
      setLoading(false);
      message.error(error.message, 4);
    });
    history.push("/");
  };
  const handleLoginTwitter = async () => {
    setLoading(true);
    await signInWithTwitter().catch((error) => {
      setLoading(false);
      message.error(error.message, 4);
    });
    history.push("/");
  };
  return (
    <React.Fragment>
      <NavBar
        style={{ backgroundColor: "#fff", height: 50 }}
        icon={
          <ArrowBackIcon />
        }
        onLeftClick={() => history.replace("/")}
      />
      <div
        style={{
          width: "100%",
          // height: "500px",
          // position: "absolute",
          // top: "45%",
          // transform: "translateY(-50%)",
          display: "flex",
          flexFlow: "column",
          height: "90%",
        }}
      >
        <div
          style={{
            flex: "0 1 auto",
            width: "219px",
            margin: "auto",
            marginBottom: "74px",
            left: 0,
            backgroundColor: "#fff",
          }}
        >
          <img src={logo} alt="" style={{ width: "219px" }} />
        </div>
        <div style={{ flex: "1 1 auto" }}>
          <WingBlank>
            <div style={{ width: "80%", margin: "0 auto" }}>
              <Form onSubmit={handleSubmit}>
                <TextInput
                  label=""
                  formItemOptions={{
                    wrapperCol: {
                      xs: { span: 24 },
                      sm: { span: 24 },
                    },
                  }}
                  prefix={<Icon type="mail" style={{ fontSize: 13 }} />}
                  name="email"
                  placeholder="Email"
                  size="large"
                  style={{ borderColor: "#54BFC5", height: "45px" }}
                  formRules={[
                    {
                      required: true,
                      message: "Please input your E-mail",
                    },
                  ]}
                />
                <TextInput
                  label=""
                  formItemOptions={{
                    wrapperCol: {
                      xs: { span: 24 },
                      sm: { span: 24 },
                    },
                  }}
                  prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                  name="password"
                  placeholder="Password"
                  type="password"
                  size="large"
                  style={{ borderColor: "#54BFC5", height: "45px" }}
                  formRules={[
                    {
                      required: true,
                      message: "Please input your Password",
                    },
                  ]}
                />
                <SubmitButtonInput
                  block
                  formItemOptions={{
                    wrapperCol: {
                      xs: { span: 24 },
                      sm: { span: 24 },
                    },
                  }}
                  style={{
                    background: "#54BFC5",
                    color: "white",
                    width: "100%",
                    textAlign: "center",
                    border: "1px solid #54BFC5",
                    fontWeight: "bold",
                    height: "39px",
                  }}
                  size="large"
                >
                  LOGIN
                </SubmitButtonInput>
                <Typography.Paragraph
                  style={{ textAlign: "center", fontSize: "12px" }}
                >
                  <Link to="/forgetpassword" style={{ color: "#54BFC5" }}>
                    forget password?
                  </Link>
                </Typography.Paragraph>
              </Form>
            </div>
          </WingBlank>
        </div>
        <ActivityIndicator toast text="Loading..." animating={loading} />
        <div
          style={{
            width: "100%",
            backgroundColor: "#fff",
            // marginTop: "24px"
            flex: "0 1 40px",
          }}
        >
          <Typography
            style={{
              textAlign: "center",
              fontSize: "12px",
              color: "#000",
              marginBottom: "12px",
            }}
          >
            or log in with
          </Typography>
          <div style={{ margin: "auto", width: "220px" }}>
            <div style={{ display: "inline", margin: "auto" }}>
              <img
                src={fbLogo}
                alt=""
                style={{ paddingRight: "40px" }}
                onClick={() => handleLoginFacebook()}
              />
              <img
                src={gLogo}
                alt=""
                style={{ width: "48px", marginTop: "7px" }}
                onClick={() => handleLoginGoogle()}
              />
              <img
                src={twitLogo}
                alt=""
                style={{ paddingLeft: "40px" }}
                onClick={() => handleLoginTwitter()}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MobileSignin;
