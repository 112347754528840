import React from "react";
import VerifyEmail from "./VerifyEmail";
import ResetPassword from "./ResetPassword";
import { useLocation } from "react-router";
import qs from "qs";

const AuthAction = () => {
  const location = useLocation();
  const search = location.search;
  const query = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  console.log(query);
  const { mode } = query;
  if (mode === "verifyEmail") {
    return <VerifyEmail query={query} />;
  } else if (mode === "resetPassword") {
    return <ResetPassword query={query} />
  }
  return <div>Unknown auth action</div>;
};

export default AuthAction;
