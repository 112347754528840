import React, { useState } from "react";
import { useAuth } from "@manow/auth";
import { Icon, Spin, Typography, message, Button } from "antd";
import { useHistory, Link } from "react-router-dom";
import Form from "../../components/form/Form";
import TextInput from "../../components/form/TextInput";
import SubmitButtonInput from "../../components/form/SubmitButtonInput";
import logo from "../../asset/logo.png";

const DesktopSignin = () => {
  const {
    login,
    signInWithGoogle,
    signInWithFacebook,
    signInWithTwitter,
  } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const handleSubmit = async (formData) => {
    setLoading(true);
    await login(formData).catch((error) => {
      setLoading(false);
      message.error(error.message, 4);
    });
    history.push("/event");
  };
  const handleLoginGoogle = async () => {
    setLoading(true);
    await signInWithGoogle().catch((error) => {
      setLoading(false);
      message.error(error.message, 4);
    });
    history.push("/event");
  };
  const handleLoginFacebook = async () => {
    setLoading(true);
    await signInWithFacebook().catch((error) => {
      setLoading(false);
      message.error(error.message, 4);
    });
    history.push("/event");
  };
  const handleLoginTwitter = async () => {
    setLoading(true);
    await signInWithTwitter().catch((error) => {
      setLoading(false);
      message.error(error.message, 4);
    });
    history.push("/event");
  };
  const gotoSignUP = () => {
    history.push("/signup");
  };
  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
          <div style={{ width: "274px", margin: "0 auto" }}>
            <img
              src={logo}
              alt=""
              style={{ width: "274px", marginTop: "24px" }}
            />
          </div>
          <Typography
            style={{
              textAlign: "center",
              margin: "14px 0 9px 0",
              fontWeight: "bold",
              fontSize: "18px",
              color: "#000",
            }}
          >
            Log In
          </Typography>
          {/* <Typography
            style={{ textAlign: "center", fontSize: "12px", fontWeight: "300", color: "#000", marginBottom: "10px" }}
          >
            Don't have an account?{" "}
            <Link style={{ fontSize: "12px", color: "#54BFC5" }} to="/signup">
              Sign Up Free
            </Link>{" "}
            or{" "}
            <Link
              style={{ fontSize: "12px", color: "#54BFC5" }}
              to="/forgetpassword"
            >
              Forget Password ?
            </Link>
          </Typography> */}
          <TextInput
            label=""
            formItemOptions={{
              wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
              },
            }}
            prefix={<Icon type="mail" style={{ fontSize: 13 }} />}
            name="email"
            placeholder="Email"
            size="large"
            style={{ borderColor: "#54BFC5", height: "45px" }}
            formRules={[
              {
                required: true,
                message: "Please input your Email",
              },
            ]}
          />
          <TextInput
            label=""
            formItemOptions={{
              wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
              },
            }}
            suffix={
              <Icon
                type="eye"
                theme="filled"
                style={{ color: "#000" }}
                onClick={() => setShowPwd(!showPwd)}
              />
            }
            name="password"
            placeholder="Password"
            type={showPwd ? "text" : "password"}
            size="large"
            style={{ borderColor: "#54BFC5", height: "45px" }}
            formRules={[
              {
                required: true,
                message: "Please input your Password",
              },
            ]}
          />
          <SubmitButtonInput
            block
            formItemOptions={{
              wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
              },
            }}
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              background: "#54BFC5",
              color: "white",
              width: "100%",
              textAlign: "center",
              height: "46px",
              borderRadius: "10px",
            }}
            size="large"
          >
            Log In
          </SubmitButtonInput>
          <Button
            block
            size="large"
            style={{
              background: "#fff",
              border: "1px solid #54BFC5",
              color: "#54BFC5",
              width: "100%",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "bold",
              height: "46px",
              borderRadius: "10px",
            }}
            onClick={() => gotoSignUP()}
          >
            Register
          </Button>
          <Typography
            style={{
              textAlign: "center",
              fontSize: "12px",
              fontWeight: "300",
              color: "#000",
              marginBottom: "10px",
              marginTop: "15px",
            }}
          >
            Can't remember your account?{" "}
            <Link
              style={{ fontSize: "12px", color: "#54BFC5" }}
              to="/forgetpassword"
            >
              Forget Password
            </Link>{" "}
          </Typography>
          <Typography
            style={{
              textAlign: "center",
              margin: "20px 0",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "21px",
              color: "#000",
            }}
          >
            OR
          </Typography>
          <Button
            block
            size="large"
            style={{
              background: "#4469B0",
              color: "white",
              width: "100%",
              textAlign: "center",
              marginBottom: "19px",
              fontSize: "12px",
              height: "46px",
            }}
            onClick={() => handleLoginFacebook()}
          >
            <div
              style={{
                display: "flex",
                width: "60%",
                height: "28px",
                margin: "5px auto",
              }}
            >
              <Icon
                type="facebook"
                theme="filled"
                style={{ fontSize: "28px" }}
              />
              <div style={{ padding: "5px 0 0 10px" }}>
                Continue with Facebook
              </div>
            </div>
          </Button>
          <Button
            block
            size="large"
            style={{
              background: "#D94A42",
              color: "white",
              width: "100%",
              textAlign: "center",
              marginBottom: "19px",
              fontSize: "12px",
              height: "46px",
            }}
            onClick={() => handleLoginGoogle()}
          >
            <div
              style={{
                display: "flex",
                width: "60%",
                height: "28px",
                margin: "5px auto",
              }}
            >
              <Icon type="google" style={{ fontSize: "28px" }} />
              <div style={{ padding: "5px 0 0 10px" }}>
                Continue with Google
              </div>
            </div>
          </Button>
          <Button
            block
            size="large"
            style={{
              background: "#00ACEE",
              color: "white",
              width: "100%",
              textAlign: "center",
              marginBottom: "31px",
              fontSize: "12px",
              height: "46px",
            }}
            onClick={(e) => handleLoginTwitter()}
          >
            <div
              style={{
                display: "flex",
                width: "60%",
                height: "28px",
                margin: "5px auto",
              }}
            >
              <Icon type="twitter" style={{ fontSize: "28px" }} />
              <div style={{ padding: "5px 0 0 10px" }}>
                Continue with Twitter
              </div>
            </div>
          </Button>
          <div
            style={{
              fontSize: "12px",
              fontWeight: "300",
              textAlign: "center",
              width: "100%",
              wordBreak: "break-all",
              color: "#000",
              marginBottom: "35px",
            }}
          >
            <Typography
              style={{ color: "#000", fontSize: "12px", fontWeight: "300" }}
            >
              By continuing, I accept Manow{" "}
              <a
                href={process.env.PUBLIC_URL + "/Terms of service.pdf"}
                rel="noopener noreferrer"
                target="_blank"
                style={{ color: "#54BFC5" }}
              >
                terms of service
              </a>
              <br />
              and have read the{" "}
              <a
                href={process.env.PUBLIC_URL + "Privacy Policy.pdf"}
                rel="noopener noreferrer"
                target="_blank"
                style={{ color: "#54BFC5" }}
              >
                privacy policy
              </a>
            </Typography>
          </div>
        </Form>
      </Spin>
    </React.Fragment>
  );
};

export default DesktopSignin;
