"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var firebase_1 = require("../firebase");

var firebase_2 = require("firebase");

var EventAgendaRefPath = function EventAgendaRefPath(EventKey) {
  return firebase_1.FirebaseApp.firestore().collection('Event').doc(EventKey).collection('EventAgenda');
};

exports.CreateEventAgenda = function (EventKey, Data) {
  return rxjs_1.from(EventAgendaRefPath(EventKey).add(__assign(__assign({}, Data), {
    EventAgendaCreateTimestamp: firebase_2.firestore.FieldValue.serverTimestamp()
  })));
};

exports.UpdateEventAgenda = function (EventKey, EventAgendaKey, Data) {
  return rxjs_1.from(EventAgendaRefPath(EventKey).doc(EventAgendaKey).set(__assign(__assign({}, Data), {
    EventAgendaUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteEventAgenda = function (EventKey, EventAgendaKey) {
  return rxjs_1.from(EventAgendaRefPath(EventKey).doc(EventAgendaKey).delete());
};

exports.GetEventAgenda = function (EventKey, EventAgendaKey) {
  return firestore_1.docData(EventAgendaRefPath(EventKey).doc(EventAgendaKey), 'EventAgendaKey');
};

exports.GetAllEventAgendaList = function (EventKey) {
  return firestore_1.collectionData(EventAgendaRefPath(EventKey).orderBy('EventAgendaStartTimestamp', 'asc'), 'EventAgendaKey');
};

exports.QueryEventAgendaList = function (EventKey, QueryField, QueryOperator, QueryValue) {
  return firestore_1.collectionData(EventAgendaRefPath(EventKey).where(QueryField, QueryOperator, QueryValue), 'EventAgendaKey');
};

exports.DeleteAllEventAgenda = function (EventKey) {
  return exports.GetAllEventAgendaList(EventKey).pipe(operators_1.map(function (EventAgendaList) {
    var DeleteAllEventAgendaListBatch = firebase_1.FirebaseApp.firestore().batch();
    EventAgendaList.map(function (EventAgendaDoc) {
      return DeleteAllEventAgendaListBatch.delete(EventAgendaRefPath(EventKey).doc(EventAgendaDoc.EventAgendaKey));
    });
    return DeleteAllEventAgendaListBatch.commit();
  }));
};