import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import DesktopSignin from "./DesktopSignin";
import MobileSignin from "./MobileSignin";
import { Card } from "antd";

const Signin = () => {
  return (
    <Card
      style={{
        width: !isMobile && !isTablet ? "350px" : "100%",
        maxWidth: "100%",
        margin: "0 auto",
      }}
      bordered={false}
      bodyStyle={{ padding: "0" }}
    >
      {!isMobile && !isTablet ? <DesktopSignin /> : <MobileSignin />}
    </Card>
  );
};

export default Signin;
