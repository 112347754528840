import React, { useState } from "react";
import { NavBar, Icon, WingBlank, ActivityIndicator, Toast } from "antd-mobile";
import { Typography, Row, Col, Button } from "antd";
import { useAuth } from "@manow/auth";
import { User } from "@manow/service";
import Form from "../../components/form/Form";
import TextInput from "../../components/form/TextInput";
import SubmitButtonInput from "../../components/form/SubmitButtonInput";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "../../components/icon/ArrowBackIcon";

const MobileSignup = () => {
  const { register } = useAuth();
  const history = useHistory();
  const [signedUpEmail, setsignedUpEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const handleSubmit = async (formData) => {
    setLoading(true);
    await register({
      email: formData.email,
      password: formData.password,
      continueURL: global.window.location.origin,
    })
      .then((res) => {
        const userInfo = {
          UserInfoEmail: formData.email,
          UserInfoCompany: formData.companyname,
          UserInfoJobPosition: formData.position,
          UserInfoFirstName: formData.firstname,
          UserInfoLastName: formData.lastname,
          UserInfoPhone: formData.phone,
          UserInfoRegistrationTimestamp: new Date(),
        };
        User.UpdateUserInfo(res.uid, userInfo).subscribe(async (res) => {
          setsignedUpEmail(formData.email);
        });
      })
      .catch((error) => {
        Toast.fail(error.message, 1);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const checkPasswordRange = (rule, value, callback, source, options) => {
    if (value.length <= 0) {
      callback("Please input Password");
    } else if (value.length < 6) {
      callback("Password should be at least 6 characters");
    } else {
      callback();
    }
  };
  const compareToFirstPassword = (
    rule,
    value,
    callback,
    source,
    options,
    pwd
  ) => {
    if (value.length <= 0) {
      callback("Please input Password");
    } else if (value.length < 6) {
      callback("Password should be at least 6 characters");
    }
    if (value && value !== pwd) {
      callback("Two passwords that you enter is inconsistent!");
    }
    callback();
  };

  const compareToFirstEmail = (
    rule,
    value,
    callback,
    source,
    options,
    email
  ) => {
    if (value && value !== email) {
      callback("Two E-mail that you enter is inconsistent!");
    }
    callback();
  };
  if (signedUpEmail) {
    return (
      <React.Fragment>
        <WingBlank>
          <Typography.Title>ขอบคุณสำหรับการลงทะเบียน</Typography.Title>
          <Typography.Paragraph>
            การลงทะเบียนของคุณยังไม่สมบูรณ์
          </Typography.Paragraph>
          <Typography.Paragraph>
            กรุณาตรวจสอบอีเมลจาก Manow และคลิกที่ลิงค์ยืนยันอีเมล
            การลงทะเบียนจึงจะสมบูรณ์
          </Typography.Paragraph>
          <Button
            block
            style={{
              background: "#54BFC5",
              color: "white",
              width: "100%",
              textAlign: "center",
              border: "1px solid #54BFC5",
              fontWeight: "bold",
            }}
            onClick={() => history.push("/mobilesignin")}
          >
            LOGIN
          </Button>
          <Button block type="link" onClick={() => history.push("/")}>
            Go to home
          </Button>
        </WingBlank>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <NavBar
        style={{ backgroundColor: "#fff", height: 50 }}
        icon={<ArrowBackIcon />}
        onLeftClick={() => history.replace("/")}
      />
      <WingBlank>
        <Typography
          style={{
            color: "#888888",
            marginTop: "14px",
            marginBottom: "45px",
            marginLeft: "12px",
            fontSize: "24px",
            fontWeight: "600",
          }}
        >
          Sign up
        </Typography>
        <div style={{ width: "80%", margin: "0 auto" }}>
          <Form onSubmit={handleSubmit}>
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              formRules={[
                {
                  required: true,
                  message: "Please input your E-mail",
                },
              ]}
              prefix={<Icon type="mail" style={{ fontSize: 13 }} />}
              name="email"
              placeholder="E-mail"
              size="large"
              style={{ borderColor: "#54BFC5", height: "40px" }}
              onChangeFunc={setEmail}
            />
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              formRules={[
                {
                  required: true,
                  message: "Please input Confirm E-mail",
                },
                {
                  validator: (rule, value, callback, source, options) => {
                    compareToFirstEmail(
                      rule,
                      value,
                      callback,
                      source,
                      options,
                      email
                    );
                  },
                },
              ]}
              prefix={<Icon type="mail" style={{ fontSize: 13 }} />}
              name="confirmEmail"
              placeholder="Confirm E-mail"
              size="large"
              style={{ borderColor: "#54BFC5", height: "40px" }}
            />
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              formRules={[
                // {
                //   required: true,
                //   message: "Please input Password",
                // },
                {
                  validator: (rule, value, callback, source, options) =>
                    checkPasswordRange(rule, value, callback, source, options),
                },
              ]}
              prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
              name="password"
              type="password"
              placeholder="Password"
              size="large"
              style={{ borderColor: "#54BFC5", height: "40px" }}
              onChangeFunc={setPwd}
            />
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              formRules={[
                // {
                //   required: true,
                //   message: "Please input Confirm Password",
                // },
                {
                  validator: (rule, value, callback, source, options) => {
                    compareToFirstPassword(
                      rule,
                      value,
                      callback,
                      source,
                      options,
                      pwd
                    );
                  },
                },
              ]}
              prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
              name="confirmPassword"
              type="password"
              placeholder="Confirm password"
              size="large"
              style={{ borderColor: "#54BFC5", height: "40px" }}
            />
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              prefix={<Icon type="user" style={{ fontSize: 13 }} />}
              name="firstname"
              placeholder="First Name"
              size="large"
              formRules={[
                {
                  required: true,
                  message: "Please input your First Name",
                },
              ]}
              style={{ borderColor: "#54BFC5", height: "45px" }}
            />
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              prefix={<Icon type="user" style={{ fontSize: 13 }} />}
              name="lastname"
              placeholder="Last Name"
              size="large"
              formRules={[
                {
                  required: true,
                  message: "Please input your Last Name",
                },
              ]}
              style={{ borderColor: "#54BFC5", height: "45px" }}
            />
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              prefix={<Icon type="user" style={{ fontSize: 13 }} />}
              name="phone"
              placeholder="Tel"
              size="large"
              formRules={[
                {
                  required: true,
                  message: "Please input your Telephone Number",
                  type: "string",
                  pattern: /^\d+$/
                },
              ]}
              style={{ borderColor: "#54BFC5", height: "40px" }}
            />
            <Row style={{ margin: "30px -25px" }}>
              <Col>
                <div style={{ display: "inline-flex" }}>
                  <Typography
                    style={{
                      color: "#888888",
                      fontSize: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Optional
                  </Typography>
                  <div
                    style={{
                      color: "#888888",
                      fontSize: "11px",
                      paddingTop: "11.5px",
                      paddingLeft: "5px",
                    }}
                  >
                    *not required, display for other account
                  </div>
                </div>
              </Col>
            </Row>
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              prefix={<Icon type="user" style={{ fontSize: 13 }} />}
              name="companyname"
              placeholder="Company Name"
              size="large"
              style={{ borderColor: "#54BFC5", height: "40px" }}
            />
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              prefix={<Icon type="user" style={{ fontSize: 13 }} />}
              name="position"
              placeholder="Position"
              size="large"
              style={{ borderColor: "#54BFC5", height: "40px" }}
            />
            <div
              style={{
                fontSize: "12px",
                fontWeight: "300",
                textAlign: "center",
                width: "300px",
                wordBreak: "break-all",
                margin: "30px -15px",
                color: "#000",
              }}
            >
              <Typography
                style={{ fontSize: "12px", fontWeight: "300", color: "#000" }}
              >
                By continuing, I accept Manow{" "}
                <a
                  href={process.env.PUBLIC_URL + "/Terms of service.pdf"}
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ color: "#54BFC5" }}
                >
                  terms of service
                </a>
                ,<br />
                and have read the{" "}
                <a
                  href={process.env.PUBLIC_URL + "Privacy Policy.pdf"}
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ color: "#54BFC5" }}
                >
                  privacy policy
                </a>
              </Typography>
            </div>
            <SubmitButtonInput
              block
              formItemOptions={{
                wrapperCol: {
                  xs: { span: 24 },
                  sm: { span: 24 },
                },
              }}
              style={{
                fontSize: "13px",
                background: "#54BFC5",
                color: "white",
                width: "100%",
                textAlign: "center",
                border: "1px solid #54BFC5",
                fontWeight: "bold",
                borderRadius: "15px",
              }}
              size="large"
            >
              NEXT
            </SubmitButtonInput>
          </Form>
        </div>
      </WingBlank>
      <ActivityIndicator toast text="Loading..." animating={loading} />
    </React.Fragment>
  );
};

export default MobileSignup;
