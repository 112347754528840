"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var firebaseConfig = {
  "projectId": "manow-staging-e3ccb",
  "appId": "1:854133985729:web:bc9c34c358c7f945dadf40",
  "databaseURL": "https://manow-staging-e3ccb.firebaseio.com",
  "storageBucket": "manow-staging-e3ccb.appspot.com",
  "locationId": "asia-east2",
  "apiKey": "AIzaSyCtTf5935qPJXihnq4ig6h06zqYaJw6ZMU",
  "authDomain": "manow-staging-e3ccb.firebaseapp.com",
  "messagingSenderId": "854133985729"
};
exports.default = firebaseConfig;