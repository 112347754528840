"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var firebaseConfig = {
  apiKey: 'AIzaSyC8a7lWdMqCeM4ENK41bZcdX88uJMiSi1k',
  authDomain: 'manowpoint.firebaseapp.com',
  databaseURL: 'https://manowpoint.firebaseio.com',
  projectId: 'manowpoint',
  storageBucket: 'manowpoint.appspot.com',
  messagingSenderId: '164096880022',
  appId: '1:164096880022:web:d668d6b738ac1679'
};
exports.default = firebaseConfig;