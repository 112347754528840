"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var firebase_1 = require("../firebase");

var UserBusinessCardRefPath = function UserBusinessCardRefPath(UserKey) {
  return firebase_1.FirebaseApp.firestore().collection('UserInfo').doc(UserKey).collection('UserBusinessCard');
};

var UserBusinessCardCollectionGroupRefPath = function UserBusinessCardCollectionGroupRefPath() {
  return firebase_1.FirebaseApp.firestore().collectionGroup('UserBusinessCard');
};

var UserBusinessCardCollectionGroupByEventExhibitorRefPath = function UserBusinessCardCollectionGroupByEventExhibitorRefPath(EventExhibitorKey) {
  return firebase_1.FirebaseApp.firestore().collectionGroup('UserBusinessCard').where('UserBusinessCardEventExhibitorKey', '==', EventExhibitorKey);
};

var UserBusinessCardCollectionGroupByUserRefPath = function UserBusinessCardCollectionGroupByUserRefPath(UserKey) {
  return firebase_1.FirebaseApp.firestore().collectionGroup('UserBusinessCard').where('UserBusinessCardUserKey', '==', UserKey);
};

exports.CreateUserBusinessCard = function (UserKey, Data) {
  return rxjs_1.from(UserBusinessCardRefPath(UserKey).add(Data));
};

exports.UpdateUserBusinessCard = function (UserKey, UserBusinessCardKey, Data) {
  return rxjs_1.from(UserBusinessCardRefPath(UserKey).doc(UserBusinessCardKey).set(__assign(__assign({}, Data), {
    UserBusinessCardUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteUserBusinessCard = function (UserKey, UserBusinessCardKey) {
  return rxjs_1.from(UserBusinessCardRefPath(UserKey).doc(UserBusinessCardKey).delete());
};

exports.DeleteAllUserBusinessCard = function (UserKey) {
  return firestore_1.collectionData(UserBusinessCardRefPath(UserKey), 'UserBusinessCardKey').pipe(operators_1.map(function (UserBusinessCardList) {
    var DeleteAllUserBusinessCardBatch = firebase_1.FirebaseApp.firestore().batch();
    UserBusinessCardList.map(function (UserBusinessCardDoc) {
      return DeleteAllUserBusinessCardBatch.delete(UserBusinessCardRefPath(UserKey).doc(UserBusinessCardDoc.UserBusinessCardKey));
    });
    return DeleteAllUserBusinessCardBatch.commit();
  }));
};

exports.GetUserBusinessCardList = function (UserKey) {
  return firestore_1.collectionData(UserBusinessCardRefPath(UserKey).orderBy('UserBusinessCardUpdateTimestamp', 'desc'), 'UserBusinessCardKey');
};

exports.GetUserBusinessCardDetail = function (UserKey, UserBusinessCardKey) {
  return firestore_1.docData(UserBusinessCardRefPath(UserKey).doc(UserBusinessCardKey), 'UserBusinessCardKey');
};

exports.GetUserBusinessCardFromSelectEvent = function (UserKey, EventKey) {
  var businessCards = firestore_1.collectionData(UserBusinessCardRefPath(UserKey).where('UserBusinessCardEventKey', '==', EventKey).orderBy('UserBusinessCardUpdateTimestamp', 'desc'), 'UserBusinessCardKey').pipe(operators_1.startWith([]));
  var otherBusinessCards = firestore_1.collection(UserBusinessCardCollectionGroupByUserRefPath(UserKey).where('UserBusinessCardEventKey', '==', EventKey).orderBy('UserBusinessCardUpdateTimestamp', 'desc')).pipe(operators_1.switchMap(function (UserBusinessCardList) {
    return rxjs_1.combineLatest.apply(void 0, UserBusinessCardList.map(function (UserBusinessCardData) {
      return firestore_1.doc(UserBusinessCardData.ref.parent.parent).pipe(operators_1.map(function (userinfoSnap) {
        var userinfo = userinfoSnap.data();
        var businessCardInfo = {
          UserBusinessCardKey: userinfoSnap.id,
          UserBusinessCardEventKey: EventKey,
          UserBusinessCardCompanyName: userinfo.UserInfoCompany || '',
          UserBusinessCardCompanyLogo: userinfo.UserInfoCompanyLogo || userinfo.UserInfoDisplayImage || '',
          UserBusinessCardEmail: userinfo.UserInfoEmail || '',
          UserBusinessCardFirstName: userinfo.UserInfoFirstName || '',
          UserBusinessCardLastName: userinfo.UserInfoLastName || '',
          UserBusinessCardTelNumber: userinfo.UserInfoPhone || '',
          UserBusinessCardCompanyAddress: userinfo.UserInfoCompanyAddress || '',
          UserBusinessCardWebsite: userinfo.UserInfoCompanyWebsite || '',
          UserBusinessCardPosition: userinfo.UserInfoJobPosition || '',
          UserBusinessCardUserKey: userinfoSnap.id
        };
        return businessCardInfo;
      }));
    }));
  })).pipe(operators_1.startWith([]));
  return rxjs_1.combineLatest(businessCards, otherBusinessCards).pipe(operators_1.map(function (_a) {
    var b1 = _a[0],
        b2 = _a[1];
    console.log(b1, b2);

    var res = __spreadArrays(b2, b1);

    var keys = {};
    return res.filter(function (f) {
      if (keys[f.UserBusinessCardKey]) {
        return false;
      }

      keys[f.UserBusinessCardKey] = true;
      return true;
    });
  }));
};

exports.GetAllUserBusinessCard = function () {
  return firestore_1.collection(UserBusinessCardCollectionGroupRefPath()).pipe(operators_1.switchMap(function (UserBusinessCardList) {
    return rxjs_1.combineLatest.apply(void 0, UserBusinessCardList.map(function (UserBusinessCardData) {
      var userBusinessCardData = UserBusinessCardData.data();
      return firestore_1.docData(UserBusinessCardData.ref.parent.parent, 'UserInfoKey').pipe(operators_1.map(function (UserInfoData) {
        return __assign(__assign({}, UserInfoData), userBusinessCardData);
      }));
    }));
  }));
};

exports.GetAllUserBusinessCardByEventExhibitor = function (EventExhibitorKey) {
  return firestore_1.collection(UserBusinessCardCollectionGroupByEventExhibitorRefPath(EventExhibitorKey)).pipe(operators_1.switchMap(function (UserBusinessCardList) {
    return rxjs_1.combineLatest.apply(void 0, UserBusinessCardList.map(function (UserBusinessCardData) {
      var userBusinessCardData = UserBusinessCardData.data();
      return firestore_1.docData(UserBusinessCardData.ref.parent.parent, 'UserInfoKey').pipe(operators_1.map(function (UserInfoData) {
        return __assign(__assign({}, UserInfoData), userBusinessCardData);
      }));
    }));
  }));
};