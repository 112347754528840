import React, { useState } from "react";
import {
  Icon,
  Modal,
  Typography,
  Button,
  Checkbox,
  message,
  Row,
  Col,
  Spin,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "@manow/auth";
import { User } from "@manow/service";
import Form from "../../components/form/Form";
import TextInput from "../../components/form/TextInput";
import SubmitButton from "../../components/form/SubmitButtonInput";
import logo from "../../asset/logo.png";
import arrow from "../../asset/arrow.png";

const DesktopSignup = () => {
  const {
    register,
    signInWithGoogle,
    signInWithFacebook,
    signInWithTwitter,
  } = useAuth();
  const history = useHistory();
  const [signedUpEmail, setsignedUpEmail] = useState();
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [showConPwd, setShowConPwd] = useState(false);
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const handleLoginGoogle = async () => {
    setModelLoading(true);
    await signInWithGoogle().catch((error) => {
      setModelLoading(false);
      message.error(error.message, 4);
    });
    history.push("/");
  };
  const handleLoginFacebook = async () => {
    setModelLoading(true);
    await signInWithFacebook().catch((error) => {
      setModelLoading(false);
      message.error(error.message, 4);
    });
    history.push("/");
  };
  const handleLoginTwitter = async () => {
    setModelLoading(true);
    await signInWithTwitter().catch((error) => {
      setModelLoading(false);
      message.error(error.message, 4);
    });
    history.push("/");
  };
  const handleOk = (e) => {
    setShow(false);
  };
  const handleCancel = (e) => {
    setShow(false);
  };
  const handleSubmit = async (formData, checked) => {
    if (!checked) {
      message.error("Please accept term of service");
      return;
    }
    setModelLoading(true);
    await register({
      email: formData.email,
      password: formData.password,
      continueURL: global.window.location.origin,
    })
      .then((res) => {
        console.log('register res', res);
        const userInfo = {
          UserInfoEmail: formData.email,
          UserInfoCompany: formData.companyname,
          UserInfoJobPosition: formData.position,
          UserInfoFirstName: formData.firstname,
          UserInfoLastName: formData.lastname,
          UserInfoPhone: formData.phone,
          UserInfoRegistrationTimestamp: new Date(),
        };
        User.UpdateUserInfo(res.uid, userInfo).subscribe(async (res) => {
          setShow(false);
          setsignedUpEmail(formData.email);
        });
      })
      .catch((error) => {
        message.error(error.message, 4);
      })
      .finally(() => {
        setModelLoading(false);
      });
  };
  const onChangeCheckbox = (e) => {
    if (e.target.checked) {
      setChecked(e.target.checked);
    } else {
      setChecked(e.target.checked);
    }
  };

  const checkPasswordRange = (rule, value, callback, source, options) => {
    if (value.length <= 0) {
      callback("Please input Password");
    } else if (value.length < 6) {
      callback("Password should be at least 6 characters");
    } else {
      callback();
    }
  };

  const compareToFirstPassword = (
    rule,
    value,
    callback,
    source,
    options,
    pwd
  ) => {
    if (value.length <= 0) {
      callback("Please input Password");
    } else if (value.length < 6) {
      callback("Password should be at least 6 characters");
    }
    if (value && value !== pwd) {
      callback("Two passwords that you enter is inconsistent!");
    }
    callback();
  };

  const compareToFirstEmail = (
    rule,
    value,
    callback,
    source,
    options,
    email
  ) => {
    if (value && value !== email) {
      callback("Two E-mail that you enter is inconsistent!");
    }
    callback();
  };

  if (signedUpEmail) {
    return (
      <React.Fragment>
        <Typography.Title>ขอบคุณสำหรับการลงทะเบียน</Typography.Title>
        <Typography.Paragraph>
          การลงทะเบียนของคุณยังไม่สมบูรณ์
        </Typography.Paragraph>
        <Typography.Paragraph>
          กรุณาตรวจสอบอีเมลจาก Manow และคลิกที่ลิงค์ยืนยันอีเมล
          การลงทะเบียนจึงจะสมบูรณ์
        </Typography.Paragraph>
        <Button block type="primary" onClick={() => history.push("/signin")}>
          Sign in
        </Button>
        <Button block type="link" onClick={() => history.push("/")}>
          Go to home page
        </Button>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div style={{ width: "274px", margin: "0 auto 74px auto" }}>
        <img
          src={logo}
          alt=""
          style={{ width: "274px", marginTop: "24px" }}
        />
      </div>
      <Button
        block
        size="large"
        style={{
          background: "#4469B0",
          color: "white",
          width: "100%",
          textAlign: "center",
          marginBottom: "19px",
          fontSize: "14px",
          fontWeight: "bold",
          height: "57px",
        }}
        onClick={() => handleLoginFacebook()}
      >
        <div
          style={{
            display: "inline-flex",
            width: "100%",
            height: "28px",
            margin: "5px 0 0 36px",
          }}
        >
          <Icon type="facebook" theme="filled" style={{ fontSize: "28px" }} />
          <div style={{ padding: "5px 0 0 10px" }}>Continue with Facebook</div>
        </div>
      </Button>
      <Button
        block
        size="large"
        style={{
          background: "#D94A42",
          color: "white",
          width: "100%",
          textAlign: "center",
          marginBottom: "19px",
          fontSize: "14px",
          fontWeight: "bold",
          height: "57px",
        }}
        onClick={() => handleLoginGoogle()}
      >
        <div
          style={{
            display: "inline-flex",
            width: "100%",
            height: "28px",
            margin: "5px 0 0 36px",
          }}
        >
          <Icon type="google" style={{ fontSize: "28px" }} />
          <div style={{ padding: "5px 0 0 10px" }}>Continue with Google</div>
        </div>
      </Button>
      <Button
        block
        size="large"
        style={{
          background: "#00ACEE",
          color: "white",
          width: "100%",
          textAlign: "center",
          marginBottom: "42px",
          fontSize: "14px",
          fontWeight: "bold",
          height: "57px",
        }}
        onClick={(e) => handleLoginTwitter()}
      >
        <div
          style={{
            display: "inline-flex",
            width: "100%",
            height: "28px",
            margin: "5px 0 0 36px",
          }}
        >
          <Icon type="twitter" style={{ fontSize: "28px" }} />
          <div style={{ padding: "5px 0 0 10px" }}>Continue with Twitter</div>
        </div>
      </Button>
      <Typography
        style={{
          textAlign: "center",
          margin: "0 0 42px 0",
          fontWeight: "bold",
          fontSize: "14px",
          color: "#000",
        }}
      >
        OR
      </Typography>
      <Button
        block
        size="large"
        style={{
          background: "#54BFC5",
          color: "white",
          width: "100%",
          textAlign: "center",
          marginBottom: "19px",
          fontSize: "14px",
          fontWeight: "bold",
          height: "57px",
        }}
        onClick={() => setShow(true)}
      >
        Sign Up with Email
      </Button>
      <Typography.Paragraph
        style={{
          textAlign: "center",
          fontSize: "12px",
          fontWeight: "300",
          color: "#000",
        }}
      >
        Already have an account?{" "}
        <Link
          to="/signin"
          style={{ fontSize: "12px", fontWeight: "300", color: "#54BFC5" }}
        >
          Log In
        </Link>
      </Typography.Paragraph>
      <div
        style={{
          fontSize: "12px",
          fontWeight: "300",
          color: "#000",
          textAlign: "center",
          marginTop: "63px",
          wordBreak: "break-all",
          marginBottom: "35px",
        }}
      >
        <Typography
          style={{ fontSize: "12px", fontWeight: "300", color: "#000" }}
        >
          By continuing, I accept Manow{" "}
          <a
            href={process.env.PUBLIC_URL + "/Terms of service.pdf"}
            rel="noopener noreferrer"
            target="_blank"
            style={{ fontSize: "12px", color: "#54BFC5" }}
          >
            terms of service
          </a>
          <br /> and have read the{" "}
          <a
            href={process.env.PUBLIC_URL + "Privacy Policy.pdf"}
            rel="noopener noreferrer"
            target="_blank"
            style={{ fontSize: "12px", color: "#54BFC5" }}
          >
            privacy policy
          </a>
        </Typography>
      </div>

      <Modal
        visible={show}
        onOk={(e) => handleOk(e)}
        onCancel={(e) => handleCancel(e)}
        footer={null}
      >
        <Spin spinning={modelLoading}>
          <Form onSubmit={(e) => handleSubmit(e, checked)}>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              <img src={logo} alt="" style={{ width: "178px" }} />
            </div>
            <Typography
              style={{
                color: "#B9B9B9",
                marginBottom: "160px",
                fontSize: "24px",
                fontWeight: "600",
              }}
            >
              Sign up
            </Typography>
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              formRules={[
                {
                  required: true,
                  message: "Please input your E-mail",
                },
              ]}
              prefix={<Icon type="mail" style={{ fontSize: 13 }} />}
              name="email"
              placeholder="E-mail"
              size="large"
              style={{ borderColor: "#54BFC5", height: "45px" }}
              onChangeFunc={setEmail}
            />
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              formRules={[
                {
                  required: true,
                  message: "Please input Confirm E-mail",
                },
                {
                  validator: (rule, value, callback, source, options) => {
                    compareToFirstEmail(
                      rule,
                      value,
                      callback,
                      source,
                      options,
                      email
                    );
                  },
                },
              ]}
              prefix={<Icon type="mail" style={{ fontSize: 13 }} />}
              name="confirmEmail"
              placeholder="Confirm E-mail"
              size="large"
              style={{ borderColor: "#54BFC5", height: "45px" }}
            />
            <TextInput
              label=""
              formItemOptions={{
                wrapperCol: {
                  xs: { span: 24 },
                  sm: { span: 24 },
                },
              }}
              suffix={
                <Icon
                  type="eye"
                  theme="filled"
                  style={{ color: "#000" }}
                  onClick={() => setShowPwd(!showPwd)}
                />
              }
              name="password"
              placeholder="Password"
              type={showPwd ? "text" : "password"}
              size="large"
              style={{ borderColor: "#54BFC5", height: "45px" }}
              formRules={[
                // {
                //   required: true,
                //   message: "Please input Password",
                // },
                {
                  validator: (rule, value, callback, source, options) =>
                    checkPasswordRange(rule, value, callback, source, options),
                },
              ]}
              onChangeFunc={setPwd}
            />
            <TextInput
              label=""
              formItemOptions={{
                wrapperCol: {
                  xs: { span: 24 },
                  sm: { span: 24 },
                },
              }}
              suffix={
                <Icon
                  type="eye"
                  theme="filled"
                  style={{ color: "#000" }}
                  onClick={() => setShowConPwd(!showConPwd)}
                />
              }
              name="confirmPassword"
              placeholder="Confirm password"
              type={showConPwd ? "text" : "password"}
              size="large"
              style={{ borderColor: "#54BFC5", height: "45px" }}
              formRules={[
                // {
                //   required: true,
                //   message: "Please input Confirm Password",
                // },
                {
                  validator: (rule, value, callback, source, options) => {
                    compareToFirstPassword(
                      rule,
                      value,
                      callback,
                      source,
                      options,
                      pwd
                    );
                  },
                },
              ]}
            />
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              prefix={<Icon type="user" style={{ fontSize: 13 }} />}
              name="firstname"
              placeholder="First Name"
              size="large"
              formRules={[
                {
                  required: true,
                  message: "Please input your First Name",
                },
              ]}
              style={{ borderColor: "#54BFC5", height: "45px" }}
            />
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              prefix={<Icon type="user" style={{ fontSize: 13 }} />}
              name="lastname"
              placeholder="Last Name"
              size="large"
              formRules={[
                {
                  required: true,
                  message: "Please input your Last Name",
                },
              ]}
              style={{ borderColor: "#54BFC5", height: "45px" }}
            />
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              prefix={<Icon type="user" style={{ fontSize: 13 }} />}
              name="phone"
              placeholder="Tel"
              size="large"
              formRules={[
                {
                  required: true,
                  message: "Please input your Telephone Number",
                  type: "string",
                  pattern: /^\d+$/
                },
              ]}
              style={{ borderColor: "#54BFC5", height: "45px" }}
            />
            <Row style={{ margin: "57px 0 43px 0" }}>
              <Col span={6}>
                <Typography
                  style={{
                    color: "#B9B9B9",
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  Optional{" "}
                </Typography>
              </Col>
              <Col span={18}>
                <Typography
                  style={{
                    color: "#B9B9B9",
                    fontSize: "12px",
                    paddingTop: "13.5px",
                  }}
                >
                  *not required, display for other account
                </Typography>
              </Col>
            </Row>
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              prefix={<Icon type="user" style={{ fontSize: 13 }} />}
              name="companyname"
              placeholder="Company Name"
              size="large"
              style={{ borderColor: "#54BFC5", height: "45px" }}
            />
            <TextInput
              formItemOptions={{ formItemLayout: null }}
              prefix={<Icon type="user" style={{ fontSize: 13 }} />}
              name="position"
              placeholder="Position"
              size="large"
              style={{ borderColor: "#54BFC5", height: "45px" }}
            />
            <Checkbox
              name="chkaccept"
              checked={checked}
              style={{ marginBottom: "22px", fontSize: "12px" }}
              onChange={onChangeCheckbox}
            >
              I accept Manow{" "}
              <a
                href={process.env.PUBLIC_URL + "/Terms of service.pdf"}
                rel="noopener noreferrer"
                target="_blank"
                style={{ color: "#54BFC5" }}
              >
                terms of service
              </a>{" "}
              and{" "}
              <a
                href={process.env.PUBLIC_URL + "Privacy Policy.pdf"}
                rel="noopener noreferrer"
                target="_blank"
                style={{ color: "#54BFC5" }}
              >
                privacy policy
              </a>
            </Checkbox>
            <div>
              <Row>
                <Col span={12}>
                  <div
                    className="icons-list"
                    style={{
                      fontSize: "12px",
                      cursor: "pointer",
                      color: "#000",
                    }}
                    onClick={() => setShow(false)}
                  >
                    <img
                      src={arrow}
                      alt=""
                      style={{
                        marginRight: "20px",
                        marginTop: "-2px",
                        width: "34px",
                        transform: "rotate(-90deg)",
                      }}
                    />
                    Choose other sign up options
                  </div>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <SubmitButton
                    formItemOptions={{ formItemLayout: null }}
                    type="primary"
                    style={{
                      background: "#54BFC5",
                      color: "white",
                      textAlign: "center",
                      fontWeight: "bold",
                      width: "128px",
                      height: "40px",
                    }}
                  >
                    Continue
                  </SubmitButton>
                </Col>
              </Row>
            </div>
          </Form>
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

export default DesktopSignup;
