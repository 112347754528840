import React from "react";
import { Button, WingBlank } from "antd-mobile";
import { useHistory } from "react-router-dom";
import logo from "../../asset/logo.png";

const MobileAuthPortal = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div
        style={{
          height: "400px",
          width: "100%",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)"
        }}
      >
        <WingBlank>
          <div
            style={{
              width: "219px",
              margin: "auto",
              // marginTop: "50%",
              marginBottom: "44px"
            }}
          >
            <img src={logo} alt="" style={{ width: "219px" }} />
          </div>
          <Button
            size="large"
            style={{
              fontWeight: "bold",
              color: "#54BFC5",
              border: "1px solid #54BFC5",
              borderRadius: "15px",
              width: "80%",
              margin: "0 auto"
            }}
            onClick={() => history.push("/signup")}
          >
            SIGN UP
          </Button>
          <Button
            size="large"
            style={{
              fontWeight: "bold",
              backgroundColor: "#54BFC5",
              color: "#fff",
              borderRadius: "15px",
              width: "80%",
              margin: "27px auto 0 auto"
            }}
            onClick={() => history.push("/signin")}
          >
            LOGIN
          </Button>
        </WingBlank>
      </div>
    </React.Fragment>
  );
};

export default MobileAuthPortal;
