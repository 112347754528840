import { useAuth } from "./provider";
export var AuthenticatedArea = function AuthenticatedArea(_a) {
  var children = _a.children;
  var user = useAuth().user;
  return user ? children : null;
};
export var UnauthenticatedArea = function UnauthenticatedArea(_a) {
  var children = _a.children;
  var user = useAuth().user;
  return !user ? children : null;
};