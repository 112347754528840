"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var firebase_1 = require("../firebase");

var event_1 = require("./event");

var EventAttendeeRefPath = function EventAttendeeRefPath(EventKey) {
  return firebase_1.FirebaseApp.firestore().collection('Event').doc(EventKey).collection('EventAttendee');
};

var OrderTransactionPath = function OrderTransactionPath() {
  return firebase_1.FirebaseApp.firestore().collection('OrderTransaction');
};

exports.CreateEventAttendee = function (EventKey, Data) {
  return rxjs_1.combineLatest(rxjs_1.from(EventAttendeeRefPath(EventKey).add(Data)), event_1.UpdateEvent(EventKey, {
    EventAttendeeList: firebase_1.FirestoreFieldValue.arrayUnion(Data.UserKey)
  })).pipe(operators_1.map(function (_a) {
    var EventExhibitorSource = _a[0];
    return EventExhibitorSource;
  }));
};

exports.UpdateEventAttendee = function (EventKey, EventAttendeeKey, Data) {
  return rxjs_1.from(EventAttendeeRefPath(EventKey).doc(EventAttendeeKey).set(__assign(__assign({}, Data), {
    EventAttendeeUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteEventAttendee = function (EventKey, EventAttendeeKey, UserKey) {
  return rxjs_1.combineLatest(rxjs_1.from(EventAttendeeRefPath(EventKey).doc(EventAttendeeKey).delete()), event_1.UpdateEvent(EventKey, {
    EventAttendeeList: firebase_1.FirestoreFieldValue.arrayRemove(UserKey)
  }));
};

var getOrderInfo = function getOrderInfo(attendee) {
  return OrderTransactionPath().doc(attendee.OrderTransactionKey).get().then(function (doc) {
    var orderData = doc.data();
    return orderData;
  }).catch(function (e) {
    console.log(e);
  });
};

exports.GetAllEventAttendeeList = function (EventKey) {
  return firestore_1.collectionData(EventAttendeeRefPath(EventKey).orderBy('EventAttendeeCreateTimestamp', 'desc'), 'EventAttendeeKey').pipe(operators_1.flatMap(function (attendees) {
    return __awaiter(void 0, void 0, void 0, function () {
      var attendeeWithOrderInfo;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4, Promise.all(attendees.map(function (attendee) {
              return __awaiter(void 0, void 0, void 0, function () {
                var orderInfo;
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4, getOrderInfo(attendee)];

                    case 1:
                      orderInfo = _a.sent();
                      return [2, __assign(__assign({}, attendee), {
                        orderInfo: orderInfo
                      })];
                  }
                });
              });
            }))];

          case 1:
            attendeeWithOrderInfo = _a.sent();
            return [2, attendeeWithOrderInfo];
        }
      });
    });
  }));
};

exports.GetEventAttendeeDetail = function (EventKey, EventAttendeeKey) {
  return firestore_1.docData(EventAttendeeRefPath(EventKey).doc(EventAttendeeKey), 'EventAttendeeKey').pipe(operators_1.take(1));
};

exports.QueryEventAttendeeList = function (EventKey, QueryField, QueryOperator, QueryValue) {
  return firestore_1.collectionData(EventAttendeeRefPath(EventKey).where(QueryField, QueryOperator, QueryValue), 'EventAttendeeKey');
};

exports.DeleteAllEventAttendee = function (EventKey) {
  return exports.GetAllEventAttendeeList(EventKey).pipe(operators_1.map(function (EventAttendeeList) {
    var DeleteAllEventAttendeeListBatch = firebase_1.FirebaseApp.firestore().batch();
    EventAttendeeList.map(function (EventAttendeeDoc) {
      return DeleteAllEventAttendeeListBatch.delete(EventAttendeeRefPath(EventKey).doc(EventAttendeeDoc.EventAttendeeKey));
    });
    return DeleteAllEventAttendeeListBatch.commit();
  }));
};

exports.GetUserAttendee = function (UserKey) {
  return firestore_1.collectionData(event_1.EventRefPath().where('EventAttendeeList', 'array-contains', UserKey), 'EventKey');
};