import React, { useState } from "react";
import { useAuth } from "@manow/auth";
import { useHistory } from "react-router-dom";
import { Typography, Spin, Card, Icon, message, Button } from "antd";
import Form from "../../components/form/Form";
import TextInput from "../../components/form/TextInput";
import SubmitButtonInput from "../../components/form/SubmitButtonInput";

const ResetPassword = ({ query }) => {
  const { oobCode: actionCode } = query;
  const history = useHistory();
  const { passwordReset } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [completed, setCompleted] = useState(false);
  const handleSumbit = async (e) => {
    setLoading(true);
    let isValid = true;
    await passwordReset(actionCode, e.password).catch((error) => {
      message.error(error.message, 4);
      isValid = false;
    });
    setLoading(false);
    setCompleted(isValid);
  };
  const goTo = () => {
    history.push("/signin");
  };
  return (
    <React.Fragment>
      <Card
        style={{
          width: 350,
          maxWidth: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        bordered={false}
        bodyStyle={{ padding: "0" }}
      >
        <Spin spinning={loading}>
          <Card
            style={{
              marginTop: "48px",
            }}
            bordered={false}
            loading={loading}
          >
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#B9B9B9",
                marginBottom: "24px",
              }}
            >
              Reset password
            </Typography>
            {!completed && (
              <Form onSubmit={(e) => handleSumbit(e)}>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "#000",
                    marginBottom: "24px",
                  }}
                >
                  Please enter your password below.
                </Typography>
                <TextInput
                  label=""
                  formItemOptions={{
                    wrapperCol: {
                      xs: { span: 24 },
                      sm: { span: 24 },
                    },
                  }}
                  suffix={
                    <Icon
                      type="eye"
                      theme="filled"
                      style={{ color: "#000" }}
                      onClick={() => setShowPwd(!showPwd)}
                    />
                  }
                  name="password"
                  placeholder="Password"
                  type={showPwd ? "text" : "password"}
                  size="large"
                  style={{ borderColor: "#54BFC5", height: "45px" }}
                  formRules={[
                    {
                      required: true,
                      message: "Please input your Password",
                    },
                  ]}
                />
                <SubmitButtonInput
                  block
                  formItemOptions={{
                    wrapperCol: {
                      xs: { span: 24 },
                      sm: { span: 24 },
                    },
                  }}
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    background: "#54BFC5",
                    color: "white",
                    width: "100%",
                    textAlign: "center",
                    height: "40px",
                  }}
                  size="large"
                >
                  Reset Password
                </SubmitButtonInput>
              </Form>
            )}
            {completed && (
              <React.Fragment>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    marginBottom: "24px",
                  }}
                >
                  A password reset successful.
                </Typography>
                <Button
                  block
                  formItemOptions={{
                    wrapperCol: {
                      xs: { span: 24 },
                      sm: { span: 24 },
                    },
                  }}
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    background: "#54BFC5",
                    color: "white",
                    width: "100%",
                    textAlign: "center",
                    height: "40px",
                  }}
                  size="large"
                  onClick={() => goTo()}
                >
                  Back to Login
                </Button>
              </React.Fragment>
            )}
          </Card>
        </Spin>
      </Card>
    </React.Fragment>
  );
};

export default ResetPassword;
