"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var firebase_1 = require("../firebase");

var EventInteractiveStageRefPath = function EventInteractiveStageRefPath(EventKey) {
  return firebase_1.FirebaseApp.firestore().collection('Event').doc(EventKey).collection('EventInteractiveStage');
};

exports.CreateEventInteractiveStage = function (EventKey, Data) {
  return rxjs_1.from(EventInteractiveStageRefPath(EventKey).add(Data));
};

exports.UpdateEventInteractiveStage = function (EventKey, EventInteractiveStageKey, Data) {
  return rxjs_1.from(EventInteractiveStageRefPath(EventKey).doc(EventInteractiveStageKey).set(__assign(__assign({}, Data), {
    UserInfoUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteEventInteractiveStage = function (EventKey, EventInteractiveStageKey) {
  return rxjs_1.from(EventInteractiveStageRefPath(EventKey).doc(EventInteractiveStageKey).delete());
};

exports.GetAllEventInteractiveStageList = function (EventKey) {
  return firestore_1.collectionData(EventInteractiveStageRefPath(EventKey), 'EventInteractiveStageKey');
};

exports.GetEventInteractiveStageDetail = function (EventKey, EventInteractiveStageKey) {
  return firestore_1.docData(EventInteractiveStageRefPath(EventKey).doc(EventInteractiveStageKey), 'EventInteractiveStageKey');
};

exports.QueryEventInteractiveStageList = function (EventKey, QueryField, QueryOperator, QueryValue) {
  return firestore_1.collectionData(EventInteractiveStageRefPath(EventKey).where(QueryField, QueryOperator, QueryValue), 'EventInteractiveStageKey');
};

exports.DeleteAllEventInteractiveStage = function (EventKey) {
  return exports.GetAllEventInteractiveStageList(EventKey).pipe(operators_1.map(function (EventInteractiveStageList) {
    var DeleteAllEventInteractiveStageListBatch = firebase_1.FirebaseApp.firestore().batch();
    EventInteractiveStageList.map(function (EventInteractiveStageDoc) {
      return DeleteAllEventInteractiveStageListBatch.delete(EventInteractiveStageRefPath(EventKey).doc(EventInteractiveStageDoc.EventInteractiveStageKey));
    });
    return DeleteAllEventInteractiveStageListBatch.commit();
  }));
};