import React, { useEffect, useState } from "react";
import { Button, Result } from "antd";
import { useHistory } from "react-router";
// import { useAuth } from '@manow/auth'
import { FirebaseApp } from "@manow/service";

const auth = FirebaseApp.auth();
const VerifyEmail = ({ query }) => {
  const [isVerified, setisVerified] = useState(false);
  const [error, seterror] = useState(false);
  const history = useHistory();
  const { oobCode: actionCode, continueUrl, lang } = query;
  const handleVerifyEmail = (actionCode) => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    auth
      .applyActionCode(actionCode)
      .then(function(resp) {
        setisVerified(true);
        console.log(resp);
        // Email address has been verified.
      })
      .catch(function(error) {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        console.log(error);
        seterror(error);
      });
  };
  useEffect(() => {
    handleVerifyEmail(actionCode, continueUrl, lang);
  }, [actionCode, continueUrl, lang]);

  if (error) {
    return (
      <div style={{ width: "100%" }}>
        <Result
          status="error"
          title="Email verification fail"
          subTitle={error.message}
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/")}
            >
              กลับสู่หน้าหลัก
            </Button>,
          ]}
        />
      </div>
    );
  }
  if (!isVerified) {
    return null;
  }
  return (
    <div style={{ width: "100%" }}>
      <Result
        status="success"
        title="ยืนยันอีเมลสำเร็จ"
        subTitle="ขอบคุณที่ลงทะเบียนกับ Manow ขณะนี้คุณลงทะเบียนสำเร็จแล้ว"
        extra={[
          <Button type="primary" key="console" href={continueUrl || "/signin"}>
            ดำเนินการต่อ
          </Button>,
        ]}
      />
    </div>
  );
};

export default VerifyEmail;
